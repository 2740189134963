<!-- 添加链接弹框 -->
<template>
  <!-- title="添加链接" -->
  <el-dialog
    :title="$t('component_chatter_link_label_title')"
    width="400px"
    append-to-body
    :visible.sync="showLinkAlert"
    :before-close="handleClose"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <div>
      <div>
        <span class="title-url">
          <!-- 名称 -->
          {{ $t("label_chatter_name") }} ：
        </span>
        <el-input type="text" v-model="inputName" class="lianjie"></el-input>
      </div>
      <div style="margin-top: 15px">
        <span class="title-url">
          <!-- 网址 -->
          {{ $t("label.mr.title.URL") }} ：
        </span>
        <!-- 请输入网址 -->
        <el-input type="url" v-model="inputLink" class="lianjie"> </el-input>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancleLink" size="mini">
        <!-- 取消 -->
        {{ $t("button_partner_cancel") }}
      </el-button>
      <el-button type="primary" @click="shureLink" size="mini">
        <!-- 确定 -->
        {{ $t("label.ems.confirm") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isShowLinkAlert: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showLinkAlert: "",
      inputName: "", //添加链接的名称
      inputLink: "http://", //添加链接的网址
    };
  },
  watch: {
    isShowLinkAlert: {
      handler: function (newVal) {
        this.showLinkAlert = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    //取消发表附件动态
    cancleLink() {
      this.$emit("cancleLink");
      this.inputName = "";
      this.inputLink = "http://";
    },
    handleClose() {
      this.$emit("cancleLink");
      this.inputName = "";
      this.inputLink = "http://";
    },
    CheckUrl(val) {
      var reg =
        /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&amp;:/~+#]*[\w\-@?^=%&amp;/~+#])?/;
      if (reg.test(val)) {
        return true;
      } else {
        this.$message.warning(this.$i18n.t("label.input.right.url"));
        this.inputLink = "http://";
      }
    },
    //确定按钮
    shureLink() {
      if (this.CheckUrl(this.inputLink)) {
        if (this.inputName && this.inputLink) {
          this.$message.success(
            this.$i18n.t("vue_label_chatter_add_link_success")
          );

          this.$emit("shureLink", this.inputName, this.inputLink);
          this.inputName = "";
          this.inputLink = "http://";
        } else {
          this.$message.info(this.$i18n.t("vue_label_chatter_name_link"));
        }
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.lianjie {
  outline: none;
  width: 80%;
  height: 30px;
  line-height: 30px;
  ::v-deep .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
}
.title-url {
  width: 60px;
  display: inline-block;
}
</style>