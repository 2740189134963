<!-- 编辑弹框 -->
<template>
  <!-- 编辑帖子或者编辑评论 -->
  <el-dialog
    style="text-align: left"
    :title="
      editType == 'post'
        ? $t('vue_label_chatter_edit_post')
        : $t('label.queue.edit') + $t('vue_label_chatter_comment')
    "
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    append-to-body
    width="500px"
  >
    <div style="width: 100%">
      <el-input
        type="textarea"
        class="textareaCss"
        @input="changInputValue(getAddress)"
        v-model="getAddress"
      >
      </el-input>
    </div>
    <!-- @功能组件 -->
    <at-List
      :atListIsShow="atListIsShow"
      :problemValue="editValue"
      @chooseNameValue="chooseNameValue"
    ></at-List>
    <div class="link-file">
      <!-- 链接  不是分享帖子，有链接，不是评论帖子-->
      <div
        class="shareBody"
        v-show="linkName && editType != 'comment' && !postEdit.orgauthorId"
      >
        <div class="share">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-website"></use>
          </svg>
          <a :href="linkValue" v-html="linkName"> </a>
          <svg class="icon close" aria-hidden="true" @click="deleteWebsite()">
            <use href="#icon-chahao"></use>
          </svg>
        </div>
      </div>
      <!-- 文件 -->
      <div class="website-box" v-show="postEdit.feedType == 'D'">
        <!-- 进入页面显示的文件 -->
        <div
          class="web-item"
          v-for="(item, index) in activeUpFileList"
          :key="index"
        >
          <div class="fileName" v-html="fileNameType(item)"></div>
          <div class="filesize">
            {{ item.fileLength ? item.fileLength : convertSize(item.filesize) }}
          </div>
          <svg class="icon" aria-hidden="true" @click="deleteFile(index)">
            <use href="#icon-chahao"></use>
          </svg>
        </div>
      </div>
    </div>

    <div class="left-box">
      <div
        class="addOption"
        v-show="editType != 'comment' && !postEdit.orgauthorId"
      >
        <div
          @click="showLinkAlert"
          v-show="postEdit.feedType == 'L' && !postEdit.orgauthorId"
        >
          <svg class="icon" aria-hidden="true">
            <use href="#icon-link"></use>
          </svg>
        </div>
        <div
          @click="postFile"
          v-show="postEdit.feedType == 'D' && !postEdit.orgauthorId"
        >
          <svg class="icon" aria-hidden="true">
            <use href="#icon-uploadfile"></use>
          </svg>
        </div>
      </div>
    </div>
    <!-- 发帖人信息 -->
    <!-- {{postEdit}} -->
    <div class="inforBox" v-show="editType != 'comment'">
      <div class="personInfor">
        <div class="personLeft">
          <div>
            <img
              :src="`${imgUrlCookie}/chatterzone.action?m=showPhoto&userid=${postEdit.authorId}&binding=${tokenCookie}`"
            />
          </div>
          <!-- {{postEdit}} -->
          <div class="personName">
            <div>{{ postEdit.authorIdname }}</div>
            <div>{{ postEdit.createDate }}</div>
          </div>
          <div class="publish">{{ postType(postEdit) }}</div>
        </div>
      </div>
      <div class="postValue">
        <div style="display: flex">
          <div>
            <p style="margin: 0px" v-html="postEdit.editBody"></p>
          </div>
          <div v-if="postEdit.feedType === 'L'" class="edit-link">
            <svg class="icon" aria-hidden="true">
              <use href="#icon-website"></use>
            </svg>
            <a :href="postEdit.linkValue">
              {{ postEdit.linkName }}
            </a>
          </div>
        </div>

        <div class="share-file" v-if="postEdit.feedFile">
          <div
            class="files"
            v-for="(fileitem, fileindex) in postEdit.feedFile"
            :key="fileindex"
          >
            <!-- 文件中的图片类型 -->
            <div v-show="isYesImgType(fileitem.contentType)" class="share-img">
              <img
                :src="`${imgUrlCookie}/chatterfile.action?m=preview&fileid=${fileitem.fileid}&binding=${tokenCookie}`"
                alt=""
              />
            </div>
            <!-- 文件中的非图片类型 -->
            <div class="file-item" v-show="!isYesImgType(fileitem.contentType)">
              <div class="file-img">
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-file"></use>
                </svg>
                <div
                  v-show="!fileitem.filename"
                  style="
                    margin-top: 6px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding: 0 10px;
                  "
                >
                  <!-- 您没有权限查看该文件 -->
                  {{ $t("vue_label_chatter_no_permission") }}
                </div>
              </div>
              <div class="file-name">
                <div
                  class="name"
                  v-show="fileitem.filename"
                  v-html="fileitem.filename + '.' + fileitem.contentType"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelEdit" size="mini">
        <!-- 取消 -->
        {{ $t("button_partner_cancel") }}
      </el-button>
      <el-button
        type="primary"
        @click="saveEdit"
        size="mini"
        style="background: #1b5297"
      >
        <!-- 共享 -->
        {{ $t("label.chat.micropost.share.info") }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { GetCcchatMicroPostRUpdateMicroPost } from "../api.js";
import VueCookies from "vue-cookies";
import atList from "@/views/colleagueCircle/components/dynamic-atList.vue";
export default {
  props: {
    dialogVisibleedit: {
      //是否显示编辑弹框
      type: Boolean,
      default: false,
    },
    postEdit: {
      //帖子数据
      type: Object,
      default: () => {},
    },
    feedId: {
      type: String,
      default: "",
    },
    commentEdit: {
      //评论数据
      type: Object,
      default: () => {},
    },
    //当前类型是帖子，评论
    editType: {
      type: String,
      default: "",
    },
    // 添加网址名称
    addLinkName: {
      type: String,
      default: "",
    },
    // 添加网址链接
    addLinkValue: {
      type: String,
      default: "",
    },
    //帖子数据
    addUpFileList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    atList,
  },
  data() {
    return {
      dialogVisible: false,
      editValue:
        this.editType == "post"
          ? this.postEdit.editBody
          : this.commentEdit.editBody,
      //编辑弹框内容
      isshowpostFile: false, //文件
      linkValue: "", //网址
      linkName: "", //网址名称
      UpFileList: this.addUpFileList ? this.addUpFileList : [], //上传文件
      tokenCookie: "", //token
      imgUrlCookie: "", //域名
      atIndex: -1, //@出现的下标
      atListIsShow: false, //是否显示@列表
    };
  },
  computed: {
    // 判断帖子类型
    postType(data) {
      return () => {
        if (data.feedType === "L") {
          return this.$i18n.t("chatter.feed.publishfeed"); //"发表张贴"
        } else if (data.feedType === "D") {
          return this.$i18n.t("chatter.feed.publishfeed"); //"发表张贴"
        } else if (data.feedType === "G") {
          return this.$i18n.t("vue_label_ccchat_notice_updaterecordsuccess"); //"已更新此记录";
        } else if (data.feedType === "V") {
          return this.$i18n.t("label.weixin.avote"); // "发起投票";
        } else {
          return this.$i18n.t("chatter.feed.publishfeed"); //"发表张贴"
        }
      };
    },
    //  把br转换成\n，进行换行
    getAddress: {
      get: function () {
        var newValue;
        if (this.editValue && this.editValue.indexOf("br") > -1) {
          newValue = this.editValue.replace(/<br>/g, "\n");
        } else {
          newValue = this.editValue;
        }
        return newValue;
      },
      set: function (value) {
        this.editValue = value;
      },
    },
    //拼接文件名字和类型
    fileNameType(item) {
      return () => {
        if (item.name) {
          return item.name + "." + item.type;
        } else {
          return item.filename + "." + item.contentType;
        }
      };
    },
    // 包含name和filename的数据
    activeUpFileList() {
      return this.UpFileList.filter(function (item) {
        return item.filename || item.name;
      });
    },
  },
  watch: {
    dialogVisibleedit: {
      handler: function (newVal) {
        this.dialogVisible = newVal;
      },
      immediate: true,
    },
    //监听帖子的变化
    postEdit() {
      this.postData();
    },
    //监听评论的变化，没有文件和链接
    commentEdit() {
      this.UpFileList = [];
      this.linkValue = "";
      this.linkName = "";
      this.editValue = this.commentEdit.editBody;
    },

    // 添加链接的网址
    addLinkValue(newvlaue) {
      this.linkValue = newvlaue;
    },
    // 添加链接的网址名
    addLinkName(newvlaue) {
      this.linkName = newvlaue;
    },
    // 添加的文件数据
    addUpFileList(newvlaue) {
      this.UpFileList = newvlaue;
    },
  },
  mounted() {
    //token
    this.tokenCookie = this.$CCDK.CCToken.getToken();
    //域名
    this.imgUrlCookie = VueCookies.get("domainName");
  },
  methods: {
    //编辑中显示的内容，帖子正文，评论正文。
    postData() {
      if (this.editType == "post") {
        // 是不是分享帖子,editValue取当前评论中的最后一个，原位置是0，经过倒序处理为最后一个
        if (this.postEdit.feedId_original) {
          if (this.postEdit.comments) {
            this.editValue =
              this.postEdit.comments[
                this.postEdit.comments.length - 1
              ].editBody;
          } else if (this.postEdit.commentsForMobile) {
            this.editValue =
              this.postEdit.commentsForMobile[
                this.postEdit.commentsForMobile.length - 1
              ].editBody;
          }
        } else {
          this.editValue = this.postEdit.editBody;
        }
        //判断是否有链接
        if (this.postEdit.linkValue && this.postEdit.feedType == "L") {
          this.linkValue = this.postEdit.linkValue;
          this.linkName = this.postEdit.linkName;
        } else {
          this.linkValue = "";
          this.linkName = "";
        }

        // 判断是否有文件类型，如果有赋值给 this.UpFileList，直接赋值更改子组件会影响父组件数据，使用push不会影响
        if (this.postEdit.feedFile && this.postEdit.feedType == "D") {
          this.UpFileList = [];
          this.linkValue = "";
          this.linkName = "";
          this.postEdit.feedFile.forEach((item) => {
            let tempObj = item;
            this.UpFileList.push(tempObj);
          });
        }
        //文件id是字符串根据，分开，赋值给对应的文件
        if (this.postEdit.fileInfoId) {
          var fileInfoId = this.postEdit.fileInfoId.split(",");
          fileInfoId.map((item, index) => {
            this.UpFileList[index].fileInfoId = item;
          });
        }
      } else if (this.editType == "comment") {
        //评论
        this.editValue = this.commentEdit.editBody;
      }
    },
    // 选中@列表后返回某人
    chooseNameValue(value) {
      this.atListIsShow = false;
      if (this.editValue != "") {
        this.editValue =
          this.editValue.slice(0, this.atIndex + 1) + "[" + value + "]";
      }
    },
    // 监听输入框的值的变化,出现@调取组件
    changInputValue(value) {
      this.atIndex = value.lastIndexOf("@");
      if (value.indexOf("@") > -1) {
        this.atListIsShow = true;
      }
    },
    // 判断是图片类型
    isYesImgType(type) {
      if (type === "JPG" || type === "jpg" || type === "png") {
        return true;
      } else if (type === "gif" || type === "svg" || type === "bmp") {
        return true;
      } else if (type === "jpeg" || type === "tif") {
        return true;
      } else {
        return false;
      }
    },

    // 转换文件大小
    convertSize(val) {
      if (val < 0.1 * 1024) {
        //如果小于0.1KB转化成B
        return val.toFixed(2) + "B";
      } else if (val < 0.1 * 1024 * 1024) {
        //如果小于0.1MB转化成KB
        return (val / 1024).toFixed(2) + "KB";
      } else if (val < 0.1 * 1024 * 1024 * 1024) {
        //如果小于0.1GB转化成MB
        return (val / (1024 * 1024)).toFixed(2) + "MB";
      }
    },
    // 添加链接按钮
    showLinkAlert() {
      this.$emit("showLinkAlert");
    },
    // 上传文件按钮
    postFile() {
      this.$emit("showUpFile", this.UpFileList);
    },
    // 删除上传文件
    deleteFile(index) {
      this.UpFileList.splice(index, 1);
    },
    //   取消编辑
    cancelEdit() {
      this.$emit("cancelEdit");
      this.linkValue = this.postEdit.linkValue;
      this.linkName = this.postEdit.linkName;
      this.postData();
    },
    //   取消编辑
    handleClose() {
      this.$emit("cancelEdit");
      this.linkValue = this.postEdit.linkValue;
      this.linkName = this.postEdit.linkName;
      this.postData();
    },
    // 删除链接
    deleteWebsite() {
      this.linkValue = "";
      this.linkName = "";
    },

    //  保存编辑的评论
    async saveEdit() {
      let params = {};
      var commentId = "";
      if (this.postEdit.orgauthorId) {
        if (this.postEdit.comments) {
          commentId = this.postEdit.comments[0].id;
        } else if (this.postEdit.commentsForMobile) {
          commentId = this.postEdit.commentsForMobile[0].id;
        }
      } else {
        commentId = "";
      }
      // 编辑帖子
      if (this.editType === "post") {
        // 修改文件类型帖子
        if (this.postEdit.feedType == "D") {
          var fileName = [];
          var fileInfoId = [];
          var fileType = [];
          if (this.UpFileList.length > 0) {
            this.UpFileList.map((item) => {
              if (item.name) {
                // name = item.name + "." + item.type;
                fileInfoId.push(item.id);
              } else {
                fileInfoId.push(item.fileInfoId);
              }
            });
          }

          params = {
            feedId: this.postEdit.id,
            body: this.editValue.trim(),
            commentId: commentId,
            fileName: fileName.toString(),
            fileInfoId: fileInfoId.toString(),
            fileType: fileType.toString(),
          };
          // 修改链接类型
        } else if (this.postEdit.feedType == "L") {
          params = {
            feedId: this.postEdit.id,
            commentId: commentId,
            body: this.editValue.trim(),
            linkName: this.linkName,
            linkValue: this.linkValue,
          };
        } else if (this.postEdit.feedType == "F") {
          // 修改发帖内容
          params = {
            // 如果是根据分享帖子的id，判断是否是分享的帖子，是的话就传comment中的id
            commentId: commentId,
            feedId: this.postEdit.id,
            body: this.editValue.trim(),
          };
        }
      } else if (this.editType === "comment") {
        //编辑评论
        if (this.commentEdit.linkValue) {
          params = {
            feedId: this.feedId,
            body: this.editValue.trim(),
            commentId: this.commentEdit.id,
            linkName: this.linkName,
            linkValue: this.linkValue,
          };
        } else {
          params = {
            feedId: this.feedId,
            body: this.editValue.trim(),
            commentId: this.commentEdit.id,
          };
        }
      }

      let res = await GetCcchatMicroPostRUpdateMicroPost(params);
      if (res.result) {
        // this.$message.success("修改成功");
        this.$message.success(this.$i18n.t("message.modify.success"));
        this.$emit("saveEdit");
      } else {
        this.$emit("cancelEdit");
        this.linkValue = this.postEdit.linkValue;
        this.linkName = this.postEdit.linkName;
        this.UpFileList = [];
        // this.$message.info("修改失败");
        this.$message.info(this.$i18n.t("vue_label_notice_modify_fail"));
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.textareaCss {
  width: 100%;
  height: 100px;
  font-size: 12px;
  color: #080707;
  border-radius: 3px;
}
::v-deep .el-textarea__inner {
  min-height: 96px !important;
}
::v-deep .lightChatContent {
  color: #006dcc !important;
}
.share-file {
  display: flex;
  flex-wrap: wrap;
}
.files {
  display: flex;
  flex-wrap: wrap;
  .file-name {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background: #f3f2f2;
    height: 26px;
    width: 100%;
    .name {
      width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 10px;
    }

    img {
      // width: 8px;
      height: 11px;
    }
  }
  .share-img {
    display: flex;
    img {
      width: 120px;
      height: 95px;
      margin: 10px 20px 0px 0;
    }
  }
  .file-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 96px;
    width: 142px;
    border: 1px solid #dddbda;
    border-radius: 3px;
    margin-top: 10px;
    margin-right: 20px;
    .file-img {
      display: flex;
      justify-content: center;
      align-content: center;
      height: 66px;
      // align-items: center;
      flex-direction: column;
      img,
      svg {
        width: 22px;
        height: 26px;
        display: inline-block;
        margin: 0 auto;
      }
    }
  }
}
.share-img {
  display: flex;
  img {
    width: 120px;
    height: 95px;
    margin: 20px 20px 0px 0;
  }
}
.left-box {
  display: flex;
  // padding-top: 5px;
  font-size: 12px;
}
.website-box {
  font-size: 12px;
  // margin: 10px 10px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  flex-wrap: wrap;
  .web-item {
    display: flex;
    border-radius: 3px;
    border: 1px solid #e3e2e2;
    padding: 3px 5px;
    flex-wrap: wrap;
    margin: 5px 10px 5px 0;
    .fileName {
      color: #006dcc;
      margin: 0 5px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }
    div {
      margin: 0 10px 0 10px;
    }
    svg {
      width: 10px;
      height: 10px;
      margin-top: 3px;
    }
  }
}
.addOption {
  width: 55px;
  color: #006dcc;
  font-size: 12px;
  margin: 5px 0;
  display: flex;
  justify-content: flex-start;
  div {
    margin-right: 13px;
    //  width: 12px;

    img {
      width: 12px;
    }
  }
}
.link-file {
  display: flex;
  margin-top: 5px;
}
.shareBody {
  // padding: 5px;
  // margin: 0 5px;
  display: flex;
  .share {
    display: flex;
    vertical-align: middle;
    align-items: center;
    padding: 0 5px;
    border-radius: 3px;
    border: 1px solid #e3e2e2;
  }
  img {
    width: 14px;
    height: 14px;
    display: inline-block;
  }
  .close {
    width: 10px;
    height: 10px;
    margin-left: 10px;
    display: inline-block;
  }
  a {
    margin-left: 6px;
  }
}
.inforBox {
  display: flex;

  font-size: 12px;
  flex-direction: column;
  min-height: 96px;
  border-radius: 3px;
  border: 1px solid #e3e2e2;
  .personInfor {
    display: flex;
    padding: 10px;
    .personLeft {
      display: flex;

      img {
        margin-right: 12px;
        margin-top: 5px;
        width: 26px;
        height: 26px;
        outline: none;
        border-radius: 50%;
      }
      .personName {
        margin-right: 6px;
        div:first-child {
          color: #006dcc;
        }
        div:last-child() {
          color: #706e6c;
        }
      }
      .publish {
        color: #080707;
      }
    }
  }
  .postValue {
    padding: 0 10px 10px;
    display: flex;
    flex-direction: column;
    .edit-link {
      display: flex;
      align-items: center;
      vertical-align: middle;
      margin-left: 10px;
      cursor: pointer;
      img {
        width: 14px;
        height: 14px;
        display: inline-block;
      }
      a {
        font-size: 12px;
        margin-left: 6px;
      }
    }
  }
}
</style>