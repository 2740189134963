<!-- 分享弹框 与小组成员或者是关注人员-->
<template>
  <!-- 与小组成员或者是关注人员 -->
  <el-dialog
    :title="
      sharePostTpye === 'group'
        ? $t('vue_label_chatter_share_group')
        : $t('vue_label_chatter_share_followers')
    "
    :visible.sync="showShareAlert"
    :before-close="handleClose"
    :close-on-click-modal="false"
    append-to-body
    width="510px"
    height="390px"
  >
    <div v-if="sharePostTpye === 'group'" class="groupName">
      <div style="font-size: 12px">
        <!-- 小组名 -->
        {{ $t("chatter.group.groupName") }}
      </div>
      <!--   搜索小组-->
      <el-select
        filterable
        :remote-method="queryUserGroups"
        :placeholder="$t('chatter.group.searchGroup')"
        v-model="searchKey"
        @change="shareGroup"
      >
        <el-option
          v-for="(item, index) in searchOptions"
          :key="index"
          :label="item.name"
          :value="item._id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="follow" v-if="sharePostTpye === 'follower'">
      <!-- 到 -->
      {{ $t("to") }} {{ $t("vue_label_chatter_my_followers") }}
      <!-- 我的关注者  -->
    </div>
    <!-- 共享内容 -->
    <div style="width: 100%; margin-top: 20px">
      <el-input
        type="textarea"
        @input="AtSomeBody()"
        v-model="shareValue"
        :placeholder="$t('label.weixin.input.content') + ' ...'"
      >
      </el-input>
    </div>
    <!-- @功能组件 -->
    <at-List
      :atListIsShow="atListIsShow"
      :problemValue="shareValue"
      @chooseNameValue="chooseNameValue"
    ></at-List>
    <!-- 发帖人信息 -->
    <div class="inforBox">
      <div class="personInfor">
        <div class="personLeft">
          <div>
            <img
              style="outline: none"
              :src="`${imgUrlCookie}/chatterzone.action?m=showPhoto&userid=${sharePostdata.authorId}&binding=${tokenCookie}`"
            />
          </div>
          <div class="personName">
            <div>{{ sharePostdata.authorIdname }}</div>
            <div>{{ sharePostdata.createDate }}</div>
          </div>
          <div class="publish">
            <!-- 发表张贴  -->
            {{ $t("chatter.feed.publishfeed") }}
          </div>
        </div>
      </div>
      <div class="postValue">
        <div class="filesname">
          <div v-html="sharePostdata.body"></div>
          <div v-if="sharePostdata.feedType === 'L'" class="link">
            <svg
              class="icon"
              aria-hidden="true"
              style="width: 14px; height: 14px"
            >
              <use href="#icon-website"></use>
            </svg>
            <a :href="sharePostdata.linkValue">
              {{ sharePostdata.linkName }}
            </a>
          </div>
        </div>
        <div class="share-file" v-if="sharePostdata.feedFile">
          <div
            class="files"
            v-for="(fileitem, fileindex) in sharePostdata.feedFile"
            :key="fileindex"
          >
            <!-- 文件中的图片类型 -->
            <div v-show="isYesImgType(fileitem.contentType)" class="share-img">
              <img
                :src="`${imgUrlCookie}/chatterfile.action?m=preview&fileid=${fileitem.fileid}&binding=${tokenCookie}`"
                alt=""
              />
            </div>
            <!-- 文件中的非图片类型 -->
            <div class="file-item" v-show="!isYesImgType(fileitem.contentType)">
              <div class="file-img">
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-file"></use>
                </svg>
                <div
                  v-show="!fileitem.filename"
                  style="
                    margin-top: 6px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding: 0 10px;
                  "
                >
                  <!-- 您没有权限查看该文件 -->
                  {{ $t("vue_label_chatter_no_permission") }}
                </div>
              </div>
              <div class="file-name">
                <div
                  class="name"
                  v-show="fileitem.filename"
                  v-html="fileitem.filename + '.' + fileitem.contentType"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelShare" size="mini">
        <!-- 取消 -->
        {{ $t("button_partner_cancel") }}</el-button
      >
      <el-button type="primary" @click="saveShare" size="mini">
        <!-- 共享 -->
        {{ $t("label.chat.micropost.share.info") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { queryUserGroups, shareMicroPost } from "../api";
import atList from "@/views/colleagueCircle/components/dynamic-atList.vue";
import VueCookies from "vue-cookies";

export default {
  components: { atList },
  props: {
    postShowType: {
      type: String,
      default: "",
    },
    isShowShareAlert: {
      //是否显示编辑弹框
      type: Boolean,
      default: false,
    },
    sharePostdata: {
      //是否显示编辑弹框
      type: Object,
      default: () => {},
    },
    sharePostTpye: {
      //共享帖子类型
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showShareAlert: false,
      atListIsShow: false, //是否显示@列表
      atIndex: "", //@的下标
      shareValue: "", //分享内容
      searchOptions: [], //搜索内容
      pageNum: "1", //页数
      pageSize: "10", //每页数量
      searchKey: "", //搜索小组关键字
      groupId: "",
      tokenCookie: "", //token
      imgUrlCookie: "", //域名
    };
  },
  created() {
    if (this.postShowType != "detailInfo") {
      this.queryUserGroups("");
    }
  },
  mounted() {
    //token
    this.tokenCookie = this.$CCDK.CCToken.getToken();
    //域名
    this.imgUrlCookie = VueCookies.get("domainName");
  },
  watch: {
    isShowShareAlert: {
      handler: function (newVal) {
        this.showShareAlert = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    // 选中@列表后返回某人
    chooseNameValue(value) {
      this.atListIsShow = false;
      if (this.shareValue != "") {
        this.shareValue =
          this.shareValue.slice(0, this.atIndex + 1) + "[" + value + "]";
      } else if (this.shareValue === "") {
        this.shareValue =
          this.shareValue.slice(0, this.atIndex + 1) + "[" + value + "]";
      }
    },
    // @某个人
    AtSomeBody() {
      this.atIndex = this.shareValue.lastIndexOf("@");
      if (this.shareValue.indexOf("@") > -1) {
        this.atListIsShow = true;
      }
    },
    // 判断是图片类型
    isYesImgType(type) {
      if (type === "JPG" || type === "jpg" || type === "png") {
        return true;
      } else if (type === "gif" || type === "svg" || type === "bmp") {
        return true;
      } else if (type === "jpeg" || type === "tif") {
        return true;
      } else {
        return false;
      }
    },
    //   取消分享
    cancelShare() {
      this.searchKey = "";
      this.shareValue = "";
      this.$emit("cancelShare");
      this.atListIsShow = false;
    },
    handleClose() {
      this.shareValue = "";
      this.searchKey = "";
      this.$emit("cancelShare");
    },
    //   保存分享
    async saveShare() {
      if (
        this.sharePostTpye == "group"
          ? this.shareValue && this.groupId
          : this.shareValue
      ) {
        var params = {
          //如果是分享帖子使用comment中的id，是帖子id，否则直接使用id  this.sharePostdata.feedId_original ? this.sharePostdata.comments[ this.sharePostdata.comments.length - 1].id
          feedId: this.sharePostdata.feedId_original
            ? this.sharePostdata.feedId_original
            : this.sharePostdata.id, //共享微贴ID
          body: this.shareValue, //共享留言内容
          targetType: this.sharePostTpye, //分享类型 我的追随者：follower；小组：group
          targetId: this.sharePostTpye == "group" ? this.groupId : "", //分享小组时，小组ID
        };
        let res = await shareMicroPost(params);
        if (res.result) {
          this.searchKey = "";
          this.shareValue = "";
          this.$emit("saveShare");
          this.$message.success(
            this.$i18n.t("vue_labenl_normal_notice_share_success")
          );
        }
      } else {
        if (this.sharePostTpye == "group") {
          // this.$message.info("请输入共享内容和分享小组");
          this.$message.info(
            this.$i18n.t("vue_label_chatter_enter_content_sharing")
          );
        } else {
          // this.$message.info("请输入共享内容");
          this.$message.info(
            this.$i18n.t("vue_label_chatter_enter_shared_content")
          );
        }
      }
    },
    // 搜索小组名称
    async queryUserGroups(query) {
      var res = await queryUserGroups({
        keyword: query,
        groupType: "all",
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      });
      if (res.result) {
        this.searchOptions = res.data.list;
      }
    },
    // 分享给某个小组
    shareGroup(value) {
      this.groupId = value;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner,
::v-deep .el-input--suffix .el-input__inner,
::v-deep .el-input__icon {
  height: 30px;
  line-height: 30px;
  font-size: 12px;
}

::v-deep .el-scrollbar {
  width: 400px;
}
::v-deep .el-select {
  height: 30px;
  margin-left: 20px;
}
::v-deep .el-select .el-input.is-focus .el-input__inner {
  height: 30px;
}
::v-deep .el-input--suffix .el-input__inner {
  height: 30px;
  font-size: 12px;
}
::v-deep .el-dialog__body {
  padding: 20px 36px;
  border-bottom: 0;
}
::v-deep .el-dialog__headerbtn {
  top: 17px;
}
::v-deep .el-dialog__footer {
  padding: 5px 36px 20px 36px;
}
::v-deep .el-textarea__inner {
  width: 100%;
  min-height: 96px !important ;
  font-size: 12px;
  color: #080707;
  border-radius: 3px;
}
.share-file {
  display: flex;
  flex-wrap: wrap;
}
.files {
  display: flex;
  flex-wrap: wrap;
  .file-name {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background: #f3f2f2;
    height: 26px;
    width: 100%;
    .name {
      width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 10px;
    }

    img {
      // width: 8px;
      height: 11px;
    }
  }
  .share-img {
    display: flex;
    img {
      width: 120px;
      height: 95px;
      margin: 10px 20px 0px 0;
    }
  }
  .file-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 96px;
    width: 142px;
    border: 1px solid #dddbda;
    border-radius: 3px;
    margin-top: 10px;
    margin-right: 20px;
    .file-img {
      display: flex;
      justify-content: center;
      align-content: center;
      height: 66px;
      // align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex-direction: column;
      img,
      svg {
        width: 22px;
        height: 26px;
        display: inline-block;
        margin: 0 auto;
      }
    }
  }
}

.follow {
  height: 20px;
  font-size: 12px;
}
.groupName {
  display: flex;
  height: 30px;
  align-items: center;
  vertical-align: middle;
  .search {
    width: 200px;
    height: 30px;
    margin-right: 10px;
    ::v-deep .el-input--suffix .el-input__inner,
    ::v-deep .el-input__icon,
    ::v-deep .el-input__inner,
    .el-input__icon {
      height: 30px;
      line-height: 30px;
    }
  }
}
.link {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  margin-left: 10px;
  svg {
    width: 14px;
    height: 14px;
  }
}
.inforBox {
  display: flex;
  margin-top: 15px;
  font-size: 12px;
  flex-direction: column;
  min-height: 96px;
  border-radius: 3px;
  border: 1px solid #e3e2e2;
  .personInfor {
    display: flex;
    padding: 10px;
    .personLeft {
      display: flex;

      img {
        margin-right: 12px;
        margin-top: 5px;
        width: 26px;
        height: 26px;
        outline: none;
        border-radius: 50%;
      }
      .personName {
        margin-right: 6px;
        div:first-child {
          color: #006dcc;
        }
        div:last-child() {
          color: #706e6c;
        }
      }
      .publish {
        color: #080707;
      }
    }
  }
  .postValue {
    padding: 0 10px 10px;
    display: flex;
    flex-direction: column;
    .filesname {
      display: flex;
      // align-items: center;
      vertical-align: middle;
      cursor: pointer;
      img,
      svg {
        display: inline-block;
      }
      a {
        font-size: 14px;
        margin-left: 6px;
      }
    }
  }
}
</style>
