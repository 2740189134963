<!-- 投票 -->
<template>
  <div class="dynamic-vote">
    <!-- 问题 -->
    <div class="problem">
      <div>
        <!-- 问题 -->
        {{ $t("label_tabpage_issuez") }}
      </div>
      <el-input
        class="probleminput"
        v-model="problemValue"
        @input="AtSomeBody()"
      ></el-input>
    </div>
    <at-List
      :atListIsShow="atListIsShow"
      :problemValue="problemValue"
      @chooseNameValue="chooseNameValue"
    ></at-List>

    <!-- 选项 -->
    <div class="option" v-for="(item, index) in optionList" :key="index">
      <div class="option-item">
        <div class="name">
          {{ $t("label.ems.option") }}
          <!-- 选项 -->
          {{ index + 1 }}
        </div>
        <el-input class="inputValue" v-model="item.option"></el-input>
        <div class="minus-sign" @click="minusOption(index)">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-minus"></use>
          </svg>
        </div>
        <div class="plus" @click="addOption()">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-plus"></use>
          </svg>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="left-box">
        <div class="addOption" @click="addOption()">
          <!-- 添加选项 -->
          {{ $t("label.weixin.addoption") }}
        </div>
        <div class="toperson">
          {{ $t("to") }}
          <!-- 到 -->
          {{ toSomeBody }}
        </div>
      </div>
      <!-- 通过电子邮件发送所有小组成员盒子 -->
      <div class="send_email_wrapper">
        <el-checkbox
          v-if="canSendEmailToGroupMemberStatus"
          v-model="isSendToAllMember"
          >{{ $t("label.email.all.members") }}</el-checkbox
        >
      </div>

      <div class="right-box" @click="publishPost(problemValue)">
        <!-- 共享 -->
        {{ $t("label.chat.micropost.share.info") }}
      </div>
    </div>
  </div>
</template>

<script>
import atList from "@/views/colleagueCircle/components/dynamic-atList.vue";
import * as colleagueCircleAPI from "../api";

export default {
  components: { atList },
  props: {
    //  判断是同事圈是123456、详情页是detailInfo
    postShowType: {
      type: String,
      default: "",
    },
    //从main.vue中传过来的对象,取每个_id来调取获取CCChat小组详细信息的接口
    menuSelectStatus:[Object,String],
    // 选中小组的id
    changeGroupId: {
      type: String,
      default: "",
    },
  },
  created() {
    if (this.postShowType != "detailInfo") {
      this.fntoSomeBody();
    }
    // 获取当前状态是否有发送全体小组成员电子邮件的权限
    this.getSendEmailToGroupMemberPermission();
  },
  watch: {
    menuSelectStatus() {
      this.fntoSomeBody();
    },
    postShowType() {
      this.fntoSomeBody();
    },
  },
  data() {
    return {
      canSendEmailToGroupMemberStatus: false, //是否可以发送邮件给组内成员
      isSendToAllMember: false, //通过电子邮件发送所有小组成员(true/false)
      atListIsShow: false, //是否显示@列表
      atIndex: "", //@的下标
      optionList: [
        //选项
        {
          name: this.$i18n.t("label.ems.option"), //"选项",
          option: "",
        },
        {
          name: this.$i18n.t("label.ems.option"), //"选项",
          option: "",
        },
      ],
      problemValue: "", //问题设置
      option: "",
      toSomeBody: "", //帖子发表到什么位置
      isShowEmpty: false, //判断投票项是否为空
    };
  },
  methods: {
    // 选中@列表后返回某人
    chooseNameValue(value) {
      this.atListIsShow = false;
      if (this.problemValue != "") {
        this.problemValue =
          this.problemValue.slice(0, this.atIndex + 1) + "[" + value + "]";
      } else if (this.problemValue === "") {
        this.problemValue =
          this.problemValue.slice(0, this.atIndex + 1) + "[" + value + "]";
      }
    },
    // @某个人
    AtSomeBody() {
      this.atIndex = this.problemValue.lastIndexOf("@");
      if (this.problemValue.indexOf("@") > -1) {
        this.atListIsShow = true;
      }
    },
    // 判断发表帖子到什么位置
    fntoSomeBody() {
      //同事圈主页我的关注，收藏，所有，涉及
      if (
        this.postShowType == "1" ||
        this.postShowType == "2" ||
        this.postShowType == "3" ||
        this.postShowType == "4"
      ) {
        this.toSomeBody = this.$i18n.t("vue_label_chatter_my_followers"); //"我的关注者";
      } else if (this.postShowType == "detailInfo") {
        // 详情页到这条记录
        this.toSomeBody = this.$i18n.t("chatter.this.n"); //"这条记录"
      } else if (this.postShowType == "6" || this.postShowType == "5") {
        //点击某个小组到小组名称
        if (typeof this.menuSelectStatus === "object") {
          this.toSomeBody = this.menuSelectStatus.name;
        }
      }
    },
    // 删除选项
    minusOption(index){
      if(this.optionList.length>2){
        this.optionList.splice(index,1)
      }else{
        this.$message.error(this.$i18n.t("c1033"));//"每个问题最少两个选项!"
      }
    },
    // 增加选项
    addOption() {
      var item = {
        name: this.$i18n.t("label.ems.option"), //"选项",
        option: "",
      };
      this.optionList.push(item);
    },
    //发表帖子
    async publishPost(val) {
      this.isShowEmpty = this.optionList.every((item) => item.option !== "");
      if (this.isShowEmpty) {
        var newArray = this.optionList;

        newArray.forEach((item) => {
          delete item.name;
        });
        let nameArray = this.optionList.map((item) => item.option);
        let setArray = new Set(nameArray);
        if ([...setArray].length == newArray.length) {
          var targetType = "";
          var targetId = "";
          if (this.postShowType == "6" || this.postShowType == "5") {
            targetType = "group";
            targetId = this.changeGroupId;
          } else {
            targetType = "";
            targetId = "";
          }
          let pararm = {
            feedType: "V", //	string	微贴类型 普通贴子：F；文件类型贴子（此接口不可用）：D；投票类型贴子：V；链接贴子：L
            body: val, //	string	微贴内容
            targetType: targetType, //	string	关联类型
            targetId: targetId, //	string	关联记录ID
            recordId: "", //	string	关联记录ID
            latitude: "", //	string	维度
            longitude: "", //	string	经度
            address: "", //	string	地址
            fileName: "", //	string	文件名称
            fileType: "", //	string	文件类型
            linkName: "", //	string	链接类型贴子 链接名称
            linkValue: "", //	string	链接类型贴子 链接值
            taskIdOrEventId: "", //	string	关联时间任务ID
            pollOptions: JSON.stringify(newArray), //	jsonStr	投票项 例如；｛”option”:”投票项1”;”option”:”投票项2”｝
            sendEmailToGroupMember: this.isSendToAllMember.toString(), //是否发送小组全员电子邮件，值为true发送
          };
          let res = await colleagueCircleAPI.addMicroPost(pararm);
          if (res.result) {
            this.$emit("publishPost");
            this.inputValue = "";
            this.problemValue = "";
            this.optionList = [
              //选项
              {
                name: this.$i18n.t("label.ems.option"), //"选项",
                option: "",
              },
              {
                name: this.$i18n.t("label.ems.option"), //"选项",
                option: "",
              },
            ];
            this.$message.success(
              this.$i18n.t("vue_labenl_normal_notice_share_success")
            ); //共享成功
          } else {
            this.$message.info(
              this.$i18n.t("vue_labenl_normal_notice_share_fail")
            ); //共享失败
          }
        } else {
          this.$message.error("选项不可重复!"); //label.college.duplicate.option
        }
      } else {
        this.$message.info(
          this.$i18n.t("vue_label_ccchat_notice_pleaseentercontent")
        ); //请输入发帖内容和选项
      }
    },
    // 获取当前状态是否有发送全体小组成员电子邮件的权限
    async getSendEmailToGroupMemberPermission(groupId = this.changeGroupId) {
      if (!groupId) return;
      let {
        data: { sendEmailToGroupMember },
      } = await colleagueCircleAPI.getSendEmailToGroupMemberPermission({
        groupId,
      });
      this.canSendEmailToGroupMemberStatus = sendEmailToGroupMember;
    },
  },
};
</script>

<style lang='scss' scoped>
.dynamic-vote {
  padding: 10px 20px 0 20px;
  .problem {
    display: flex;
    margin-bottom: 20px;
    font-size: 12px;
    div {
      display: flex;
      width: 57px;
      vertical-align: middle;
      align-items: center;
    }
    .probleminput {
      flex: 1;
      font-size: 12px;
      height: 30px;
      ::v-deep .el-input__inner {
        height: 30px;
        line-height: 30px;
      }
    }
  }
  .option {
    .option-item {
      display: flex;
      margin-bottom: 20px;
      font-size: 12px;
      .name {
        display: flex;
        width: 57px;
        vertical-align: middle;
        align-items: center;
      }
      .inputValue {
        font-size: 12px;
        flex: 1;
        height: 30px;
        margin-top: 4px;
        ::v-deep .el-input__inner {
          height: 30px;
          line-height: 30px;
        }
      }
      .plus {
        height: 38px;
        line-height: 38px;
        width: 14px;
        display: inline-block;
        svg {
          width: 14px;
          height: 14px;
        }
      }
      .minus-sign {
        display: inline-block;
        height: 38px;
        line-height: 38px;
        margin: 0 20px 0 29px;
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  .footer {
    display: flex;
    margin-bottom: 20px;
    // 通过电子邮件发送所有小组成员盒子
    .send_email_wrapper {
      padding-top: 12px;
      margin-right: 20px;
    }
    .left-box {
      flex: 1;
      .addOption {
        // width: 50px;
        color: #006dcc;
        font-size: 12px;
        margin-bottom: 10px;
      }
      .toperson {
        color: #080707;
        font-size: 12px;
      }
    }
    .right-box {
      font-size: 12px;
      display: flex;
      width: 56px;
      background: #1b5297;
      color: #fff;
      align-items: center;
      justify-content: center;
      height: 26px;
      border-radius: 3px;
      margin-top: 10px;
      cursor: pointer;
    }
  }
}
</style>
