<!-- CCChat -->
<template>
  <div
    class="dynamic-public"
    v-infinite-scroll="loadMorePostListEvent"
    :infinite-scroll-disabled="disabledLoadMore"
    :infinite-scroll-delay="500"
    :infinite-scroll-immediate="false"
  >
    <div v-if="postShowType != 'detailInfo'" class="title">CCChat</div>
    <!-- 张贴--投票   申请加入  -->
    <div
      v-if="isStatus === $t('chatter.group.applyToJoin')"
      class="nojurisdiction"
    >
      <div>
        <svg class="icon" aria-hidden="true">
          <use href="#icon-file404"></use>
        </svg>
      </div>
      <div class="groupjurisdiction">
        <!-- 只有小组成员才有权限查看，您可以尝试申请加入小组 -->
        {{ $t("vue_label_chatter_only_group_members_look") }}
      </div>
    </div>
    <!-- 取消申请 -->
    <div
      v-if="isStatus === $t('chatter.group.cancelRequest')"
      class="nojurisdiction"
    >
      <div>
        <svg class="icon" aria-hidden="true">
          <use href="#icon-file404"></use>
        </svg>
      </div>
      <div class="groupjurisdiction">
        <!-- 您已经提交加入申请，请等待管理员审核 -->
        {{ $t("vue_label_chatter_submitted_review") }}
      </div>
    </div>
    <!-- 同事圈的张贴投票 -->
    <el-tabs
      v-if="isShowComponents && postShowType != 'detailInfo'"
      v-model="postActiveName"
      type="card"
      @tab-click="handleClick"
    >
      <!-- 张贴 -->
      <el-tab-pane :label="$t('chatter.feed.publishfeed')" name="first">
        <dynamic-post
          :postShowType="postShowType"
          :recordName="recordName"
          @publishPost="publishPost"
          @addMicroPostD="addMicroPostD"
          :changeGroupId="changeGroupId"
          :menuSelectStatus="menuSelectStatus"
        ></dynamic-post>
      </el-tab-pane>
      <!-- 投票 -->
      <el-tab-pane :label="$t('chatter.poll')" name="second">
        <dynamic-vote
          :postShowType="postShowType"
          :recordName="recordName"
          @publishPost="publishPost"
          :menuSelectStatus="menuSelectStatus"
          :changeGroupId="changeGroupId"
        ></dynamic-vote>
      </el-tab-pane>
    </el-tabs>
    <!-- 详情页里面的动态 -->
    <div
      v-show="postShowType == 'detailInfo' && tabheight === '100%'"
      class="detail-box"
    >
      <el-button
        type="primary"
        v-if="isDetailType&& !publishCardBtn"
        size="medium"
        @click="publishCard()"
        >
        {{ $t("c1061") }}
        <svg class="icon" aria-hidden="true" >
          <use href="#icon-addZhuye"></use>
        </svg>
         </el-button>
      <div class="publish-post" v-if="!isDetailType">
        <!-- 张贴 -->
        {{ $t("label_chatter_postup") }}
      </div>
      <dynamic-post
        @publishPost="publishPost"
        @pdfDetailAddPost="pdfDetailAddPost"
        :menuSelectStatus="menuSelectStatus"
        @addMicroPostD="addMicroPostD"
        @cancelPdfPost="cancelPdfPost"
        :postShowType="postShowType"
        :isDetailType="isDetailType"
        :dataId="dataId"
        :recordName="recordName"
        :activeName="activeName"
        :isShowType="isShowType"
        :fieldValue="fieldValue"
        v-if="
          (IsShowPostCpm && publishCardBtn && isDetailType) ||
          (IsShowPostCpm && !isDetailType)
        "
      ></dynamic-post>
    </div>
    <!-- 帖子内容点赞评论 -->
    <dynamicComment
      v-if="isShowComponents && isDetailType != 'pdfView'"
      :postShowType="postShowType"
      :isClearData="isClearData"
      :changeGroupId="changeGroupId"
      :dataId="dataId"
      :postListPage="postListPage"
      :activeName="activeName"
      @isClearDataChange="isClearDataChange"
      @editPost="editPost"
      @changepostListPage="changepostListPage"
      @commentEditPost="commentEditPost"
      @stopLoadMorePostListEvent="stopLoadMorePostListEvent"
      @shareButton="shareButton"
      @delPost="delPost"
      ref="dynamicCommentList"
    ></dynamicComment>
    <!-- pdf视图帖子内容点赞评论 -->
    <dynamicCommentPdfView
      v-show="isShowComponents && isDetailType == 'pdfView'"
      :postShowType="postShowType"
      :isDetailType="isDetailType"
      :isClearData="isClearData"
      :changeGroupId="changeGroupId"
      :dataId="dataId"
      :postListPage="postListPage"
      :activeName="activeName"
      :isFirstShowMore="isFirstShowMore"
      @isClearDataChange="isClearDataChange"
      @editPost="editPost"
      @changepostListPage="changepostListPage"
      @commentEditPost="commentEditPost"
      @stopLoadMorePostListEvent="stopLoadMorePostListEvent"
      @shareButton="shareButton"
      @delPost="delPost"
      @showSeeMore="showSeeMore"
      ref="dynamicCommentPdfViewRef"
    ></dynamicCommentPdfView>
    <!-- PDF视图下按钮 -->
    <div v-if="isDetailType == 'pdfView'" class="pdf-btn">
      <div v-if="isShowSeeMore" @click="btnSeeMore" class="see-more">
        {{ $t("label.tabpage.lookatmorez")}}
      </div>
      <div v-if="postListPage>0||isShowfirstmore=='first'" @click="hideHistoryData" >{{ $t("c1058")}}</div>
    </div>
    <!-- 编辑帖子弹框 -->
    <dynamicCommentEdit
      :editType="editType"
      :addLinkName="addLinkName"
      :addLinkValue="addLinkValue"
      :addUpFileList="addUpFileList"
      :feedId="feedId"
      @cancelEdit="cancelEdit"
      @saveEdit="saveEdit"
      @showLinkAlert="showLinkAlert"
      @showUpFile="showUpFile"
      :dialogVisibleedit="dialogVisibleedit"
      :postEdit="postEdit"
      :commentEdit="commentEdit"
    ></dynamicCommentEdit>
    <!-- 是否删除帖子弹框 -->
    <dynamicDeleteAlert
      :editType="editType"
      :dialogVisibleDel="dialogVisibleDel"
      @cancelDel="cancelDel"
      @saveDel="saveDel"
      :postEdit="postEdit"
    ></dynamicDeleteAlert>
    <!-- 与小组成员或者是关注人员 分享弹框 -->
    <dynamicShareAlert
      :isShowShareAlert="isShowShareAlert"
      :postShowType="postShowType"
      :sharePostdata="sharePostdata"
      :sharePostTpye="sharePostTpye"
      @cancelShare="cancelShare"
      @saveShare="saveShare"
    ></dynamicShareAlert>
    <!-- 添加链接组件 -->
    <dynamicaddLinkAlert
      :isShowLinkAlert="isShowLinkAlert"
      @cancleLink="cancleLink"
      @shureLink="shureLink"
    ></dynamicaddLinkAlert>
    <!-- 添加文件 -->
    <add-files
      ref="addFile"
      :isFromFileCenter="false"
      :dialogAddVisible="dialogAddVisible"
      @addSubmit="addWorkSubmit"
      @close="addWorkClose"
    >
    </add-files>
  </div>
</template>

<script>
import dynamicPost from "@/views/colleagueCircle/components/dynamic-post.vue";
import dynamicVote from "@/views/colleagueCircle/components/dynamic-vote.vue";
import dynamicComment from "@/views/colleagueCircle/components/dynamic-comment.vue";
import dynamicCommentPdfView from "@/views/colleagueCircle/components/dynamic-comment-pdfView.vue";
import dynamicCommentEdit from "@/views/colleagueCircle/components/dynamic-editCommentAlert.vue";
import dynamicDeleteAlert from "@/views/colleagueCircle/components/dynamic-deleteAlert.vue";
import dynamicShareAlert from "@/views/colleagueCircle/components/dynamic-shareAlert.vue";
import dynamicaddLinkAlert from "@/views/colleagueCircle/components/dynamic-addLinkAlert.vue";
import AddFiles from "@/components/AddFiles/AddFiles.vue";

export default {
  components: {
    AddFiles,
    dynamicPost,
    dynamicVote,
    dynamicComment,
    dynamicCommentEdit,
    dynamicDeleteAlert,
    dynamicShareAlert,
    dynamicaddLinkAlert,
    dynamicCommentPdfView,
  },
  props: {
    // 是否是PDF视图详情页
    isDetailType: {
      type: String,
      default: "",
    },
    // 是否在详情页显示post组件
    IsShowPostCpm: {
      type: Boolean,
      default: true,
    },
    //详情页记录名称
    recordName: {
      type: Array,
      default: () => [],
    },
    // 详情页的收起按钮，高度为60px的时候就隐藏组件
    tabheight: {
      type: String,
      default: "",
    },
    // 详情页当前选中活动还是动态
    activeName: {
      type: String,
      default: "",
    },
    // 从同事圈进入详情页
    isShowType: {
      type: Boolean,
      default: false,
    },

    // 个人信息的名字
    fieldValue: {
      type: String,
      default: "",
    },
    postShowType: {
      type: String,
      default: "",
    },
    //从main.vue中传过来的对象,取每个_id来调取获取CCChat小组详细信息的接口
    menuSelectStatus:[Object,String],
    changeGroupId: {
      // 接收的小组id
      type: String,
      default: "",
    },

    dataId: {
      type: String,
      default: "",
    },
    isStatus: {
      type: String,
      default: "",
    },
  },

  computed: {
    isShowComponents() {
      let showComponents = false;
      //  "取消申请" 申请加入
      if (
        this.isStatus === this.$i18n.t("chatter.group.cancelRequest") ||
        this.isStatus === this.$i18n.t("chatter.group.applyToJoin")
      ) {
        showComponents = false;
      } else if (
        this.isStatus === this.$i18n.t("label_tabpage_exitz") || //退出
        this.isStatus === this.$i18n.t("label.referperson") // "所有人"
      ) {
        showComponents = true;
      } else if (
        this.postShowType == "detailInfo" &&
        this.tabheight === "100%"
      ) {
        showComponents = true;
      } else if (this.postShowType && this.postShowType != "detailInfo") {
        showComponents = true;
      }
      return showComponents;
    },
  },
  data() {
    return {
      postActiveName: "first",
      dialogVisibleedit: false, //是否显示编辑弹框
      dialogVisibleDel: false, //是否显示编辑弹框
      postEdit: {}, //帖子编辑弹框要编辑的内容
      commentEdit: {}, //评论编辑弹框内容
      editType: "", //编辑或者是删除的类型：帖子：post，评论：comment
      isShowShareAlert: false, //与小组成员或者是关注人员分享弹框
      sharePostdata: {}, //分享帖子的数据
      sharePostTpye: "", //分享帖子是的类型：小组还是关注人员
      loading: false, //加载中
      disabledLoadMore: this.isDetailType=='pdfView'?true:false, //是否禁用下拉加载更多，在正在请求中即让其为true
      postListPage: 0, //分页的页数
      isShowLinkAlert: false, //是否显示添加链接弹框
      dialogAddVisible: false, //是否显示上传文件弹框
      addLinkName: "", //添加网址名称
      addLinkValue: "", //添加网址链接
      addUpFileList: [], //上传文件
      isClearData: "", //是否清空帖子列表
      feedId: "", //帖子id
      dialogAddVisibleData: [], //编辑文件类型帖子做回显
      publishCardBtn: false, //发表张贴按钮
      isShowSeeMore:false,
      isFirstShowMore:'three',//区分第一次点击查看更多按钮
      isShowfirstmore:'',//只有10条数据是
    };
  },
  mounted() {
    // 个人详情页上传一个新头像
    this.$bus.$on("newHeadPortrait",this.ebFn);
  },
  destroyed() {
    this.$bus.$off("newHeadPortrait",this.ebFn); // 销毁
  },
  methods: {
    ebFn (){
      if (this.isDetailType == "pdfView") {
        this.$refs.dynamicCommentPdfViewRef.offtime = "";
        this.$refs.dynamicCommentPdfViewRef.getCCChats(0);
      } else {
        this.$refs.dynamicCommentList.offtime = "";
        this.$refs.dynamicCommentList.getCCChats(0);
      }
    },
    // 显示查看更多按钮
    showSeeMore(value,val){
      this.isShowSeeMore=value;
      this.isShowfirstmore=val||'';
    },
    // 点击查看更多数据
    btnSeeMore(){
      if(this.postListPage===0){
        this.isFirstShowMore='firstmore';
        // 首次点击查看更多时，如果数据小于10条隐藏查看更多按钮
        if(this.$refs.dynamicCommentPdfViewRef.feedsMaplist.length<10){
          this.isShowSeeMore=false;
          this.postListPage = 10;
        }else if(this.isShowfirstmore=='first'){
          this.isShowSeeMore=false;
          this.postListPage = 0;
        }else{
          this.postListPage += 10;
        }
      }else{
        this.isFirstShowMore='';
        this.postListPage += 10;
      }
      this.isClearData = "loadmore";
    },
    // 隐藏历史记录按钮，仅展示前三条数据
    hideHistoryData(){
      this.isFirstShowMore='three'
      this.postListPage = 0;
      this.isClearData = "hideHistory";
      this.second();
    },  
    // 取消张贴
    cancelPdfPost(){
      this.publishCardBtn = false;
    },
    // 点击发表张贴按钮
    publishCard() {
      this.publishCardBtn = true;
    },
    // 个人详情页获取动态
    personDetailPage() {
      if (this.isDetailType == "pdfView") {
        this.$refs.dynamicCommentPdfViewRef.feedsMaplist = [];
        this.$refs.dynamicCommentPdfViewRef.offtime = "";
        this.$refs.dynamicCommentPdfViewRef.selectData = "lastModifyDate";
        this.$refs.dynamicCommentPdfViewRef.newsPostId = "";
        this.$refs.dynamicCommentPdfViewRef.pageSize = 0;
        this.$refs.dynamicCommentPdfViewRef.sharePostFileIndex = "";
        this.$refs.dynamicCommentPdfViewRef.searchAbstract = "";
        this.$refs.dynamicCommentPdfViewRef.currentComment= "";
        this.$refs.dynamicCommentPdfViewRef.moreCommentLength = 1;
        this.$refs.dynamicCommentPdfViewRef.queryType = "zone";
        this.$refs.dynamicCommentPdfViewRef.getCCChats(0);
      } else {
        this.$refs.dynamicCommentList.feedsMaplist = [];
        this.$refs.dynamicCommentList.offtime = "";
        this.$refs.dynamicCommentList.selectData = "lastModifyDate";
        this.$refs.dynamicCommentList.newsPostId = "";
        this.$refs.dynamicCommentList.pageSize = 0;
        this.$refs.dynamicCommentList.sharePostFileIndex = "";
        this.$refs.dynamicCommentList.searchAbstract = "";
        this.$refs.dynamicCommentList.queryType = "zone";
        this.$refs.dynamicCommentList.getCCChats(0);
      }
    },
    // 更改清空
    isClearDataChange() {
      this.isClearData = "";
    },
    // 确定上传文件弹框
    addWorkSubmit(data) {
      this.addUpFileList = data;
      this.dialogAddVisible = false;
    },
    // 取消上传文件弹框
    addWorkClose() {
      this.dialogAddVisible = false;
    },
    showUpFile() {
      // 编辑的时候回显文件信息
      this.dialogAddVisible = true;
      this.$refs.addFile.initPage();
    },
    // 显示添加链接弹框
    showLinkAlert() {
      this.isShowLinkAlert = true;
    },
    //帖子编辑弹框
    editPost(val, type) {
      this.postEdit = val; //编辑帖子时获取的数据
      this.editType = type; //编辑帖子的类型：帖子，评论
      this.dialogVisibleedit = true;
    },
    //取消添加网址链接
    cancleLink() {
      this.isShowLinkAlert = false;
    },
    // 添加网址链接
    shureLink(name, value) {
      this.addLinkName = name;
      this.addLinkValue = value;
      this.isShowLinkAlert = false;
    },

    //评论编辑弹框
    commentEditPost(feedId, commentitem, type) {
      this.feedId = feedId;
      this.commentEdit = commentitem;
      this.editType = type;
      this.dialogVisibleedit = true;
    },
    // 取消编辑按钮
    cancelEdit() {
      this.dialogVisibleedit = false;
    },
    // 保存编辑按钮
    saveEdit() {
      this.dialogVisibleedit = false;
      this.isClearData = "refresh";
    },
    // 删除帖子弹框
    delPost(val, type) {
      this.postEdit = val;
      this.editType = type;
      this.dialogVisibleDel = true;
    },
    //取消删除
    cancelDel() {
      this.dialogVisibleDel = false;
    },
    //确定删除
    saveDel() {
      this.dialogVisibleDel = false;
      this.isClearData = "refresh";
    },
    // 与小组成员共享 与关注人员共享
    shareButton(data, type) {
      this.isShowShareAlert = true;
      this.sharePostdata = data; //共享数据
      this.sharePostTpye = type; //共享类型：小组，关注的人
    },
    //取消分享弹框按钮
    cancelShare() {
      this.isShowShareAlert = false;
    },
    //确定分享弹框按钮
    saveShare() {
      this.isShowShareAlert = false;
      this.isClearData = "refresh";
    },
    //切换发消息类型
    handleClick() {},
    // 发表 普通贴子：F；文件类型贴子（此接口不可用）：D；投票类型贴子：V；链接贴子：L
    publishPost() {
      this.isClearData = "refresh";
    },
    // PDF视图详情页下发帖后收起弹框
    pdfDetailAddPost(){
      this.publishCardBtn = false;
    },
    // 发表文件类型的帖子
    addMicroPostD() {
      this.isClearData = "refresh";
    },
    // 加载更多帖子
    loadMorePostListEvent() {
      if (!this.disabledLoadMore&&this.isDetailType != 'pdfView') {
        this.postListPage += 10;
        this.isClearData = "loadmore";
      }
    },
    // 停止加载更多
    stopLoadMorePostListEvent(e) {
      this.disabledLoadMore = e;
    },
    // 切换查看帖子类型的时候，每页数量赋值1
    changepostListPage() {
      // this.disabledLoadMore = false;
      this.postListPage = 0;
    },
    //详情页切换动态时，获取帖子数据
    second() {
      if (this.isDetailType == "pdfView") {
        this.postListPage=0;
        this.isFirstShowMore='three';
        this.$refs.dynamicCommentPdfViewRef.offtime = "";
        this.$refs.dynamicCommentPdfViewRef.feedsMaplist = [];
        this.$refs.dynamicCommentPdfViewRef.queryType = "record";
        this.$refs.dynamicCommentPdfViewRef.currentComment = "";
        this.$refs.dynamicCommentPdfViewRef.moreCommentLength = 1;
        this.$refs.dynamicCommentPdfViewRef.getCCChats(0);
      } else {
        this.$refs.dynamicCommentList.offtime = "";
        this.$refs.dynamicCommentList.feedsMaplist = [];
        this.$refs.dynamicCommentList.queryType = "record";
        this.$refs.dynamicCommentList.getCCChats(0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__item {
  font-size: 14px;
  font-weight: 400;
}
::v-deep .el-tabs__nav-scroll {
  background: #f3f2f2;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 0;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  border: 0;
}
::v-deep .el-tabs__item.is-active {
  background: #fff;
}
.dynamic-public {
  .title {
    // padding: 5px 0px 5px 10px;
    font-size: 16px;
    padding-left: 20px;
    border-bottom: 1px solid #dedcda;
    height: 37px;
    line-height: 37px;
    font-weight: 500;
    color: #080707;
  }
  .nojurisdiction {
    min-height: 500px;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img,
    svg {
      width: 150px;
      height: 80px;
      margin: 0 auto;
    }
    .groupjurisdiction {
      margin-top: 30px;
    }
  }
  .pdf-btn{
    display: flex;
    div{
      color:#2D6CFC;
      cursor: pointer;
      font-size: 12px;
    }
    .see-more{
      margin-right:29px;
      font-size: 12px;
    }
    
  }
  .el-tabs {
    padding: 10px;
  }
  ::v-deep .el-tabs__item {
    font-size: 12px;
  }
  .ccChat_content {
    padding: 0px 10px 10px 10px;
    height: 600px;
  }
  .detail-box {
    padding: 20px 20px 13px;
    position: relative;
    border-radius: 3px;
    ::v-deep .el-button{
      height: 32px!important;
    }
    .publish-post {
      padding-bottom: 10px;
      color: #080707;
      margin-left: 10px;
    }
  }
}
</style>
