<!-- 张贴 -->
<template>
  <div class="public-dynamic" :class="isDetailType ? 'dynamic-pdf' : ''">
    <!-- 共享内容··· -->
    <div class="input-box" v-show="!isPost">
      <input
        type="text"
        class="show"
        v-model="inputValue"
        v-focus
        :placeholder="$t('label.weixin.input.content') + ' ...'"
        @click="postMessage"
        @input="changInputValue(inputValue)"
      />
      <el-button style="min-width: 48px;height: 32px;" type="primary" size="small" @click="publishPost(inputValue)">{{
        $t("label.share")
      }}</el-button>
    </div>
    <div class="text-box" v-show="isPost">
      <textarea
        type="text"
        v-model="textareaValue"
        class="post"
        v-focus
        :placeholder="$t('label.weixin.input.content') + ' ...'"
        @input="changInputValue(textareaValue)"
      />
    </div>
    <!-- @功能组件 -->
    <at-List
      :atListIsShow="atListIsShow"
      :problemValue="inputValue ? inputValue : textareaValue"
      @chooseNameValue="chooseNameValue"
    ></at-List>

    <!-- 添加网址 -->
    <div v-show="linkList" class="website-box">
      <div v-for="(item, index) in linkList" :key="index" class="web-item">
        <svg class="icon" aria-hidden="true">
          <use href="#icon-website"></use>
        </svg>
        <div>{{ item.name }}</div>
        <svg class="icon" aria-hidden="true" @click="deleteWebsite(index)">
          <use href="#icon-chahao"></use>
        </svg>
      </div>
    </div>
    <!-- 添加文件夹 -->
    <div class="website-box" v-show="fileList">
      <div class="web-item" v-for="(item, index) in fileList" :key="index">
        <div class="fileName">{{ item.name }}</div>
        <div class="filesize">{{ item.size }}</div>
        <svg class="icon" aria-hidden="true" @click="deleteFile()">
          <use href="#icon-chahao"></use>
        </svg>
      </div>
    </div>
    <!-- 分享链接,上传文件 共享按钮 -->
    <div class="tosome" v-show="isPost">
      <div class="left-box">
        <div class="addOption">
          <div @click="postLink">
            <svg class="icon" aria-hidden="true">
              <use href="#icon-link"></use>
            </svg>
          </div>
          <div @click="postFile">
            <svg class="icon" aria-hidden="true">
              <use href="#icon-document"></use>
            </svg>
          </div>
          <div v-if="isDetailType" class="toperson">
            {{ $t("to") + " " + toSomeName }}
          </div>
        </div>
        <div v-if="!isDetailType" class="toperson">
          {{ $t("to") + " " + toSomeName }}
        </div>
      </div>
      <!-- 通过电子邮件发送所有小组成员盒子 -->
      <div class="send_email_wrapper">
        <el-checkbox
          v-model="isSendToAllMember"
          v-if="canSendEmailToGroupMemberStatus"
          >{{ $t("label.email.all.members") }}</el-checkbox
        >
      </div>
      <el-button v-if="isDetailType"
        style="min-width: 48px;height: 32px;margin-top:10px;margin-right:8px"
        @click="pdfCancelPost" size="small">{{ $t("label.tabpage.cancel") }}</el-button>
      <el-button
        type="primary"
        size="small"
        style="min-width: 48px;height: 32px;margin-top:10px"
        @click="publishPost(textareaValue)"
        >{{ $t("label.share") }}</el-button
      >
    </div>
    <!-- 添加链接组件 -->
    <dynamicaddLinkAlert
      :isShowLinkAlert="isShowLinkAlert"
      @cancleLink="cancleLink"
      @shureLink="shureLink"
    ></dynamicaddLinkAlert>
    <!-- 添加文件 -->
    <add-files
      ref="addFile"
      :isFromFileCenter="false"
      :dialogAddVisible="dialogAddVisible"
      @addSubmit="addWorkSubmit"
      @close="addWorkClose"
    >
    </add-files>
  </div>
</template>

<script>
import dynamicaddLinkAlert from "@/views/colleagueCircle/components/dynamic-addLinkAlert.vue";
import VueCookies from "vue-cookies";
import AddFiles from "@/components/AddFiles/AddFiles.vue";
import atList from "@/views/colleagueCircle/components/dynamic-atList.vue";
import * as colleagueCircleAPI from "../api";

export default {
  components: {
    dynamicaddLinkAlert, //添加网址链接弹框
    // addWork,
    AddFiles, //添加上传文件弹框
    atList,
  },
  props: {
    // 是否是PDF视图详情页
    isDetailType: {
      type: String,
      default: "",
    },
    //详情页记录名称
    recordName: {
      type: Array,
      default: () => [],
    },
    // 当前页面用户名字
    fieldValue: {
      type: String,
      default: "",
    },
    // 详情页当前选中活动还是动态
    activeName: {
      type: String,
      default: "",
    },
    // 从同事圈进入详情页
    isShowType: {
      type: Boolean,
      default: false,
    },
    // 判断是详情页还是同事圈
    postShowType: {
      type: String,
      default: "",
    },
    //从main.vue中传过来的对象,取每个_id来调取获取CCChat小组详细信息的接口
    menuSelectStatus: [Object,String],
    // 记录id
    dataId: {
      type: String,
      default: "",
    },
    // 选中小组的id
    changeGroupId: {
      type: String,
      default: "",
    },
  },
  computed: {
    toSomeName() {
      if (this.activeName === "second" && this.isShowType === false) {
        return this.fieldValue;
      } else {
        return this.toSomeBody;
      }
    },
  },
  mounted() {
    //token
    this.tokenCookie = this.$CCDK.CCToken.getToken();
    //域名
    this.imgUrlCookie = VueCookies.get("domainName");
    // 获取当前状态是否有发送全体小组成员电子邮件的权限
    this.getSendEmailToGroupMemberPermission();
  },
  //input 或者textarea自动获取焦点
  directives: {
    focus: {
      // 指令的定义
      inserted: function (el) {
        el.focus();
      },
      update: function (el) {
        // 聚焦元素
        el.focus();
      },
    },
  },
  data() {
    return {
      canSendEmailToGroupMemberStatus: false, //是否可以发送邮件给组内成员
      linkList: [], //帖子中添加的链接
      fileList: [
        // {
        //   name: "我是文件夹",
        //   size: "12222",
        // },
      ], //文件夹列表
      inputValue: "", //input帖子的内容
      textareaValue: "", //富文本帖子的内容
      //点击分享按钮
      isPost: this.isDetailType ? true : false,
      //点击分享链接
      isShowLinkAlert: false,
      feedType: "F", //发表帖子的类型
      linkName: "", //链接类型贴子 链接名称
      linkValue: "", //链接类型贴子 链接值
      dialogAddVisible: false, //是否显示上传文件的弹框
      uploadFile: {}, //选中的文件
      uploadForm: {},
      tokenCookie: "", //token
      imgUrlCookie: "", //域名
      toSomeBody: this.$i18n.t("vue_label_chatter_my_followers"), //"我的关注者",
      atIndex: -1, //@出现的下标
      atListIsShow: false, //是否显示@列表
      //从不同页面发帖：到 后缀不一样1、 B 在A的主页发帖就是到A，
      // 2、B在某某小组主页发帖就是到某某主页，
      // 3、B在自己的同事圈主页发帖就是到我的关注者
      // 4、B在B的个人主页就是到B
      // 5、B在B的某某小组主页到某某，
      // 6、B在记录详情页就是到这条记录
      isSendToAllMember: false, //通过电子邮件发送所有小组成员(true/false)
    };
  },
  created() {
    this.fntoSomeBody();
  },
  watch: {
    //小组信息
    menuSelectStatus() {
      this.fntoSomeBody();
    },
    //帖子页面
    postShowType() {
      this.fileList = [];
      this.fntoSomeBody();
    },
    //详情页记录名字
    recordName(newval) {
      if (newval.length > 0 && this.postShowType == "detailInfo") {
        this.toSomeBody = newval[0].fieldValue;
      }
    },
  },
  methods: {
    tosomeName() {
      if (this.activeName === "second" && this.isShowType === false) {
        return this.fieldValue;
      } else {
        return this.toSomeBody;
      }
    },
    // 选中@列表后返回某人
    chooseNameValue(value) {
      this.atListIsShow = false;
      if (this.inputValue != "") {
        this.inputValue =
          this.inputValue.slice(0, this.atIndex + 1) + "[" + value + "]";
      } else if (this.textareaValue !== "") {
        this.textareaValue =
          this.textareaValue.slice(0, this.atIndex + 1) + "[" + value + "]";
      }
    },
    // 监听输入框的值的变化,出现@调取组件
    changInputValue(value) {
      this.atIndex = value.lastIndexOf("@");
      if (value.indexOf("@") > -1) {
        this.atListIsShow = true;
      }
    },
    // 判断发表帖子到什么位置
    fntoSomeBody() {
      //同事圈主页我的关注，收藏，所有，涉及
      if (
        this.postShowType == "1" ||
        this.postShowType == "2" ||
        this.postShowType == "3" ||
        this.postShowType == "4"
      ) {
        this.toSomeBody = this.$i18n.t("vue_label_chatter_my_followers"); //"我的关注者",
      } else if (this.postShowType == "6" || this.postShowType == "5") {
        //点击某个小组到小组名称
        if (typeof this.menuSelectStatus === "object") {
          this.toSomeBody = this.menuSelectStatus.name;
        }
      }
    },
    // 取消上传文件
    addWorkClose() {
      this.dialogAddVisible = false;
    },
    // 点击上传文件确定按钮关闭弹框返回选中数据
    addWorkSubmit(data) {
      if (data) {
        //选中的文件
        this.fileList = [];
        this.uploadFile = data;
        // 把文件展示在内容对话框
        for (let index = 0; index < data.length; index++) {
          var obj = {};
          //上传文件的时候从文件库上传，文件类型取fileType，其他类型取type，返回大小为文件大小不需要处理
          if (data[index].fileType) {
            obj.name = data[index].name + "." + data[index].fileType;
          } else if (data[index].type) {
            obj.name = data[index].name + "." + data[index].type;
          }
          obj.size = data[index].filesize;
          this.fileList.push(obj);
        }
      }
      this.dialogAddVisible = !this.dialogAddVisible;
    },

    // 上传文件接口
    async addMicroPostD(val) {
      // if (val) {
      var type = "";
      var targetId = "";
      if (this.postShowType === "detailInfo") {
        type = "record"; //关联记录
        targetId = this.dataId;
      } else if (this.postShowType === "6" || this.postShowType === "5") {
        type = "group"; //小组id
        targetId = this.changeGroupId;
      }
      var fileName = [];
      var fileInfoId = [];
      for (let index = 0; index < this.uploadFile.length; index++) {
        var name = "";
        // 文件库文件使用fileType，其他类型文件使用type
        if (this.uploadFile[index].fileType) {
          name =
            this.uploadFile[index].name + "." + this.uploadFile[index].fileType;
        } else {
          name =
            this.uploadFile[index].name + "." + this.uploadFile[index].type;
        }
        fileName.push(name);
        // 文件库文件使用fileInfoId，其他类型文件使用id
        if (this.uploadFile[index].fileType) {
          fileInfoId.push(this.uploadFile[index].fileInfoId);
        } else {
          fileInfoId.push(this.uploadFile[index].id);
        }
      }
      this.uploadForm = new FormData();
      this.uploadForm.append("contJson", "");
      this.uploadForm.append("chatterfile", "");
      this.uploadForm.append("fileName", fileName);
      this.uploadForm.append("fileInfoId", fileInfoId);
      this.uploadForm.append("feedType", this.feedType);
      this.uploadForm.append("targetType", type ? type : "");
      this.uploadForm.append("targetId", targetId);
      this.uploadForm.append("recordId", targetId);
      this.uploadForm.append("body", val ? val : "");

      let res = await colleagueCircleAPI.addMicroPostD(this.uploadForm);
      if (res.result) {
        this.$emit("publishPost");
        this.textareaValue = "";
        this.inputValue = "";
        this.fileList = [];
        this.uploadFile = [];
        // 上传文件后清空选中的文件
        this.$bus.$emit("clearChooseFileList");
        // this.$message.success("共享成功");
        this.$message.success(
          this.$i18n.t("vue_labenl_normal_notice_share_success")
        );
      } else {
        // this.$message.info("共享失败");
        this.$message.info(this.$i18n.t("vue_labenl_normal_notice_share_fail"));
      }
      // } else {
      //   this.$message.info(this.$i18n.t("label_tabpage_entercontent"));
      //   // this.$message.info("请输入发帖内容");
      // }
    },
    // PDF视图取消发布帖子
    pdfCancelPost(){
      this.$emit("cancelPdfPost")
    },
    //发表帖子
    async publishPost(val) {
      if (this.uploadFile.length > 0) {
        this.feedType = "D";
        return this.addMicroPostD(val);
      } else {
        if (this.linkList.length > 0) {
          this.feedType = "L";
        } else {
          this.feedType = "F";
        }
        var type = "";
        var targetId = ""; //记录id
        var userId = "";
        if (this.postShowType === "detailInfo" && this.isShowType === true) {
          type = "record"; //关联记录
          targetId = this.dataId;
        } else if (this.postShowType === "6" || this.postShowType === "5") {
          type = "group"; //关联记录
          targetId = this.changeGroupId;
        } else if (this.isShowType === false) {
          type = "person";
          userId = this.dataId;
        }

        let pararm = {
          feedType: this.feedType, //	string	微贴类型 普通贴子：F；文件类型贴子（此接口不可用）：D；投票类型贴子：V；链接贴子：L
          body: val, //	string	微贴内容
          targetType: type ? type : "", //	string	关联类型
          targetId: targetId, //	string	关联记录ID
          recordId: "", //	string	关联记录ID
          latitude: "", //	string	维度
          longitude: "", //	string	经度
          address: "", //	string	地址
          fileName: "", //	string	文件名称
          fileType: "", //	string	文件类型
          linkName: this.linkName ? this.linkName : "", //	string	链接类型贴子 链接名称
          linkValue: this.linkValue ? this.linkValue : "", //	string	链接类型贴子 链接值
          taskIdOrEventId: "", //	string	关联时间任务ID
          pollOptions: "", //	jsonStr	投票项 例如；｛”option”:”投票项1”;”option”:”投票项2”｝
          userId: userId, //用户id
          sendEmailToGroupMember: this.isSendToAllMember.toString(), //是否发送小组全员电子邮件，值为true发送
        };
        //val 是发帖内容,链接或者是文件类型可以不要发帖内容，单独发链接和文件，普通类型发帖内容是必须填写
        if (
          this.feedType == "L" ||
          (this.feedType == "F" && val.trim().length > 0)
        ) {
          let res = await colleagueCircleAPI.addMicroPost(pararm);
          if (res.result) {
            this.$emit("publishPost", type);
            // 在PDF视图详情页下发完帖子收起发帖栏
            if(this.isDetailType){
              this.$emit('pdfDetailAddPost')
            }
            this.textareaValue = "";
            this.inputValue = "";
            this.linkList = [];
            this.linkList = [];
            // this.$message.success("共享成功");
            this.$message.success(
              this.$i18n.t("vue_labenl_normal_notice_share_success")
            );
          }
        } else {
          if (!this.isPost) {
            this.isPost = true;
          } else {
            this.$message.error(this.$i18n.t("label_tabpage_entercontent"));
          }
        }
      }
    },
    //取消添加网址链接
    cancleLink() {
      this.isShowLinkAlert = false;
    },
    //确定添加网址链接
    shureLink(name, value) {
      this.linkName = name;
      this.linkValue = value;
      this.isShowLinkAlert = false;
      var item = {
        name: name,
        website: value,
      };
      this.linkList = [];
      this.linkList.push(item);
    },
    //删除网址
    deleteWebsite(index) {
      this.linkList.splice(index, 1);
    },
    //删除文件夹
    deleteFile(index) {
      this.fileList.splice(index, 1);
    },
    //发表动态
    postMessage() {
      this.textareaValue = this.inputValue;
      this.inputValue = "";
      this.isPost = true;
    },
    //发表附件动态
    postLink() {
      if (this.fileList.length == 0) {
        this.isShowLinkAlert = true;
      } else {
        // this.$message.info("已选择上传文件类型帖子");
        this.$message.info(this.$i18n.t("vue_label_chatter_upload_file_type"));
      }
    },
    postFile() {
      if (this.linkList.length == 0) {
        this.dialogAddVisible = true;
        this.$refs.addFile.initPage();
      } else {
        // this.$message.info("已选择上传链接类型帖子");
        this.$message.info(this.$i18n.t("vue_label_chatter_upload_link_type"));
      }
    },
    // 获取当前状态是否有发送全体小组成员电子邮件的权限
    async getSendEmailToGroupMemberPermission(groupId = this.changeGroupId) {
      if (!groupId) return;
      let {
        data: { sendEmailToGroupMember },
      } = await colleagueCircleAPI.getSendEmailToGroupMemberPermission({
        groupId,
      });
      this.canSendEmailToGroupMemberStatus = sendEmailToGroupMember;
    },
  },
};
</script>

<style lang="scss" scoped>
.dynamic-pdf {
  background: #fafaf9;
}
.public-dynamic {
  font-family: "思源黑体 CN Normal";
  font-size: 12px;
  color: #080707;
  padding: 10px 10px 6px 10px;
  //input 输入框
  .input-box {
    display: flex;
    .show {
      width: calc(100% - 120px);
      height: 32px;
      border-radius: 3px;
      border: 1px solid #e3e2e2;
      outline: none;
      flex: 1;
      padding-left: 10px;
    }
    .button {
      cursor: pointer;
      width: 56px;
      margin-left: 10px;
      height: 30px;
      background: #005fb2;
      border-radius: 3px;
      color: #fff;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
      display: flex;
      margin-top: 1px;
    }
  }

  .atsomeperson {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    // color: #0b6ea4;
    line-height: 20px;
    .attitle {
      color: #666666;
      margin-bottom: 4px;
    }
    .atname {
      display: flex;
      flex-direction: column;
      height: 100px;
      overflow-y: auto;
      // border: 1px solid #dedcda;
    }

    .at-box {
      display: flex;
      vertical-align: middle;
      align-items: center;
      margin-bottom: 4px;
      font-size: 13px;
      img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
    }
    .at-box:hover {
      color: #006dcc;
      cursor: pointer;
    }
  }
  //富文本编辑框
  .text-box {
    .post {
      min-width: 320px;
      width: calc(100% - 22px);
      // width: calc(100% - 40px);
      height: 73px;
      border-radius: 3px;
      border: 1px solid #e3e2e2;
      outline: none;
      resize: none;
      padding: 10px;
    }
  }
  .website-box {
    // margin: 5px  0;
    display: flex;
    align-items: center;
    vertical-align: middle;
    flex-wrap: wrap-reverse;
    font-size: 12px;
    .web-item {
      margin-right: 30px;
      display: flex;
      border-radius: 3px;
      border: 1px solid #e3e2e2;
      padding: 3px 5px;
      margin-bottom: 5px;
      div {
        margin: 0 10px 0 10px;
      }
      img,
      svg {
        width: 12px;
        height: 12px;
        margin-top: 3px;
      }
    }
  }
  .fileName {
    color: #006dcc;
    margin: 0 5px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
  .filesize {
    color: #797979;
  }

  // 底部共享
  .tosome {
    display: flex;
    // 通过电子邮件发送所有小组成员盒子
    .send_email_wrapper {
      padding-top: 17px;
      margin-right: 20px;
      // background-color: #ccc;
    }
    .left-box {
      flex: 1;
      .addOption {
        color: #2d6cfc;
        font-size: 12px;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-start;
        padding-top: 10px;
        div {
          margin-right: 13px;
          //  width: 12px;

          img,
          svg {
            width: 12px;
          }
        }
      }
      .toperson {
        color: #080707;
        font-size: 12px;
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .right-box {
      cursor: pointer;
      display: flex;
      width: 56px;
      background: #1b5297;
      color: #fff;
      align-items: center;
      justify-content: center;
      height: 26px;
      border-radius: 3px;
      margin-top: 17px;
    }
  }
  .el-timeline-item__timestamp.is-top {
    position: relative;
    right: 250px;
    width: 200px;
    text-align: right;
  }
}
</style>
