<template>
  <div class="body" v-show="isShowPreviewImg" @click="handleMark">
    <div class="head">
      <div class="preview-header-r" @click="closePreview">
        <svg class="icon handimg" aria-hidden="true" slot="reference">
          <use href="#icon-close"></use>
        </svg>
      </div>
    </div>
    <div class="preview">
      <img
        class="viewimg"
        :src="
          `${imgUrlCookie}/chatterfile.action?m=preview&fileid=${PreviewImgId}&binding=${tokenCookie}`
        "
        alt=""
      />
    </div>
  </div>
</template>

<script>
import VueCookies from 'vue-cookies'
export default {
  props: {
    isShowPreviewImg: {
      type: Boolean,
      default: false,
    },
    PreviewImgId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tokenCookie: '', //token
      imgUrlCookie: '', //域名
    }
  },
  mounted() {
    //token
    this.tokenCookie = this.$CCDK.CCToken.getToken()
    //域名
    this.imgUrlCookie = VueCookies.get('domainName')
  },
  methods: {
    closePreview() {
      this.$emit('closePreviewImg')
    },
    handleMark() {
      this.$emit('closePreviewImg')
    },
  },
}
</script>
<style lang="scss" scoped>
.body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
}
.head {
  z-index: 101;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 60px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #ffffff;
}
.handimg {
  cursor: pointer;
}
.preview {
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .viewimg {
    // width: 500px;
    // height: 500px;
    max-width: 800px;
    max-height: 500px;
  }
}
</style>
