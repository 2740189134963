<!-- 点赞评论 -->
<template>
  <div class="dynamic" v-loading="loading">
    <div class="header-box" v-if="postShowType != 'detailInfo'">
      <!-- 顶部排序搜索 -->
      <div class="sort">
        <!-- 排序 -->
        {{ $t("label.sort") }}
        <el-select
          v-model="selectData"
          @change="changeOption"
          class="select"
          :placeholder="$t('label_tabpage_pleaseSelectz')"
        >
          <!-- placeholder="请选择" -->

          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 搜索此摘要 -->
      <el-input
        v-model="searchAbstract"
        class="search"
        prefix-icon="el-icon-search"
        @keyup.enter.native="searchEnterDynamic"
        :placeholder="$t('label.group.search.searchthisfeed')"
        :clearable="true"
        @input="searchChangeDynamic"
        @clear="clearSearch"
      >
      </el-input>
      <div class="line-border">
        <i
          :class="refreshIndex === true ? 'loadimg' : ''"
          class="el-icon-refresh-right refresh-class"
          @click="refreshData()"
        ></i>
      </div>
    </div>
    <!-- PDF视图不显示暂无数据 -->
    <!-- <div class="title-box" v-if="feedsMaplist.length === 0">
      <div class="title-img">
        <svg class="icon" aria-hidden="true">
          <use href="#icon-empty-search"></use>
        </svg>
      </div>
      <div class="title">
        {{ $t("label.dashboard.noRecord") }}
      </div>
    </div> -->
    <div class="comment" v-else>
      <div class="timeline-box">
        <div
          class="timeline-item"
          v-for="(item, index) in showDataLength(feedsMaplist)"
          :key="index"
        >
          <div class="timeline-time">{{ item.createDate }}</div>
          <div class="timeline-icon">
            <svg
              class="icon"
              :class="isjudgePostDelete(item) ? 'hand' : 'cancel-suspension'"
              style="outline: none"
              aria-hidden="true"
              @click="
                isjudgePostDelete(item) && eventDetail(item.taskIdOrEventId)
              "
            >
              <use :href="'#icon-' + showTpyeImg(item)"></use>
            </svg>
          </div>
          <div class="timeline-content">
            <div class="Post">
              <div class="personInfor">
                <!-- 共享帖子 发帖人信息栏 根据共享的未贴ID 判断是不是共享类型帖子-->
                <div class="personLeft" v-if="item.orgauthorIdname">
                  <div style="display: flex">
                    <img
                      @click="
                        enterDetailsPage(item.authorId, item.authorIdname)
                      "
                      :src="getHeadImg(item.authorId)"
                      style="outline: none; cursor: pointer; border-radius: 50%"
                    />
                  </div>
                  <div class="personName">
                    <div>
                      <span
                        class="posted"
                        @click="
                          enterDetailsPage(item.authorId, item.authorIdname)
                        "
                      >
                        {{ item.authorIdname }}
                      </span>
                    </div>
                    <div>
                      {{
                        item.comments
                          ? item.comments[item.comments.length - 1].createDate
                          : ""
                      }}
                    </div>
                  </div>
                  <div class="publish">
                    {{ $t("label.chat.micropost.share") }}
                    <!-- 共享帖子 -->
                  </div>
                </div>
                <!-- 普通帖子 发帖人信息栏 -->
                <div class="personLeft" v-else>
                  <div style="display: flex">
                    <img
                      @click="
                        enterDetailsPage(item.authorId, item.authorIdname)
                      "
                      :src="getHeadImg(item.authorId)"
                      style="outline: none; cursor: pointer; border-radius: 50%"
                    />
                  </div>
                  <div style="display: flex">
                    <div class="personName" style="display: flex">
                      <!-- 所有人名称 -->
                      <div>
                        <span
                          class="posted"
                          @click="
                            enterDetailsPage(item.authorId, item.authorIdname)
                          "
                        >
                          {{ item.authorIdname }}
                        </span>
                        <span style="margin-left: 10px; color: #666666">{{
                          postType(item)
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 帖子编辑按钮 -->
                <div class="personRight">
                  <!-- 点赞 -->
                  <div class="give-comment">
                    <div @click="praiseFeed(item, -1)" v-if="item.ispraised">
                      <svg class="icon dianzanlanImg" aria-hidden="true">
                        <use href="#icon-like"></use>
                      </svg>
                      <span>{{ item.praisenum ? item.praisenum : "" }} </span>
                    </div>
                    <div @click="praiseFeed(item, 1)" v-else>
                      <svg class="icon dianzanhuiImg" aria-hidden="true">
                        <use href="#icon-a-like-normal2"></use>
                      </svg>
                      <span>{{ item.praisenum ? item.praisenum : "" }} </span>
                    </div>

                    <div @click="commentButton(index)">
                      <svg
                        v-if="currentComment===index"
                        class="icon pinglunhuiImg"
                        aria-hidden="true"
                      >
                        <use href="#icon-Comment"></use>
                      </svg>
                      <svg v-else class="icon pinglunhuiImg" aria-hidden="true">
                        <use href="#icon-Comment-normal"></use>
                      </svg>

                      <span
                        v-if="
                          item.orgauthorIdname && item.comments
                            ? item.comments.length - 1
                            : item.comments
                        "
                      >
                        {{
                          item.orgauthorIdname && item.comments
                            ? item.comments.length - 1
                            : item.comments.length
                        }}</span
                      >
                    </div>
                    <!-- 共享到小组或者是关注的人 -->
                    <div v-if="item.isshared && postShowType != 'detailInfo'">
                      <el-dropdown trigger="hover" @command="shareButton">
                        <span class="el-dropdown-link share-group-like">
                          <svg class="icon fenxianghuiImg" aria-hidden="true">
                            <use href="#icon-fenxianghui"></use>
                          </svg>
                          <span class="share">
                            <!-- 共享 -->
                            {{ $t("label.chat.micropost.share.info") }}
                          </span>
                        </span>
                        <el-dropdown-menu
                          slot="dropdown"
                          style="text-align: left"
                        >
                          <el-dropdown-item
                            class="dropdownshare"
                            :command="{
                              type: 'group',
                              data: item,
                            }"
                          >
                            <!-- 与小组共享 -->

                            {{ $t("vue_label_chatter_share_group") }}
                          </el-dropdown-item>
                          <el-dropdown-item
                            class="dropdownshare"
                            :command="{
                              type: 'follower',
                              data: item,
                            }"
                          >
                            <!-- 与关注人员共享 -->
                            {{ $t("vue_label_chatter_share_followers") }}
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </div>
                  <!-- 收藏图标 -->
                  <div class="start" v-if="item.isfavorited">
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-start"></use>
                    </svg>
                  </div>
                  <el-dropdown trigger="hover" @command="editButton">
                    <div>
                      <svg class="icon dianzanhuiImg" aria-hidden="true">
                        <use href="#icon-xiala"></use>
                      </svg>
                    </div>
                    <el-dropdown-menu slot="dropdown" style="text-align: left">
                      <!-- 编辑 -->
                      <el-dropdown-item
                        class="dropdown"
                        v-if="item.isedit"
                        :command="{ type: 'edit', item: item }"
                      >
                        {{ $t("label.weixin.detail.edit") }}
                      </el-dropdown-item>
                      <el-dropdown-item
                        class="dropdown"
                        v-if="!item.isfavorited"
                        :command="{ type: 'collection', item: item, islike: 1 }"
                      >
                        <!-- 收藏 -->
                        {{ $t("component_chatter_button_collect") }}
                      </el-dropdown-item>
                      <el-dropdown-item
                        class="dropdown"
                        v-if="item.isfavorited"
                        :command="{
                          type: 'collection',
                          item: item,
                          islike: -1,
                        }"
                      >
                        <!-- 取消收藏 -->
                        {{ $t("chatter.coll.c") }}
                      </el-dropdown-item>
                      <el-dropdown-item
                        class="dropdown"
                        v-if="item.isdelete"
                        :command="{ type: 'del', item: item }"
                      >
                        <!-- 删除 -->
                        {{ $t("component_setup_tabs_label_delete") }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <!-- 普通类型：文件，链接，帖子内容,判断是否是事件，邮件，短信等类型取反 -->
              <div class="postContent" v-show="!isEventTypePost(item)">
                <!-- 帖子内容 -->
                <!-- 是共享帖子就展示评论数据中的第一条 -->
                <div style="display: flex">
                  <div class="postBody" v-show="showPostBody(item)">
                    <p v-html="atToPersonDetail(showPostBody(item))"></p>
                  </div>
                  <!-- 共享的帖子 comments中第一条是分享帖子的内容，其他的是评论， -->
                  <!-- 链接遍历 -->
                  <div
                    class="link"
                    v-if="item.feedType === 'L' && !item.orgauthorIdname"
                  >
                    <svg class="icon" aria-hidden="true" v-if="item.linkValue">
                      <use href="#icon-website1"></use>
                    </svg>
                    <a :href="item.linkValue" v-html="item.linkName"> </a>
                  </div>
                </div>
                <!-- 文件类型的帖子 分享类型的文件不显示-->
                <div
                  class="file-box"
                  v-if="item.feedType === 'D' && !item.feedId_original"
                >
                  <!-- <div v-html="item.body"></div> -->
                  <div class="files">
                    <!-- 文件类型 -->
                    <div
                      v-for="(fileitem, fileindex) in item.feedFile"
                      :key="fileindex"
                      class="fileTextImg"
                    >
                      <!-- 文件的类型 -->
                      <div
                        class="fileText"
                        v-if="isImgType(fileitem.contentType)"
                      >
                        <div class="file-item">
                          <div class="file-nodata">
                            <svg class="icon" aria-hidden="true">
                              <use href="#icon-document"></use>
                            </svg>
                            <div
                              v-show="!fileitem.filename"
                              style="
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                              "
                            >
                              <!-- 您没有权限查看该文件 -->
                              {{ $t("vue_label_chatter_no_permission") }}
                            </div>
                          </div>
                          <div class="file-name">
                            <div
                              class="name"
                              v-show="fileitem.filename"
                              v-html="
                                fileitem.filename + '.' + fileitem.contentType
                              "
                            ></div>
                            <svg
                              class="hand"
                              aria-hidden="true"
                              @click="downLoadFile(item, fileindex)"
                              v-show="fileitem.filename"
                            >
                              <use href="#icon-document"></use>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <!-- 图片类型 -->
                      <div
                        v-if="
                          isYesImgType(fileitem.contentType) &&
                          !item.orgauthorId
                        "
                        @click="previewImg(fileitem.fileid)"
                        class="pdffileImgs"
                      >
                        {{ fileitem.filename }}.{{ fileitem.contentType }}
                        <!-- <img
                          class="filetype-img"
                          @click="previewImg(fileitem.fileid)"
                          :src="`${imgUrlCookie}/chatterfile.action?m=preview&fileid=${
                            fileitem.fileid
                          }&binding=${tokenCookie}&dandan=${Math.random()}`"
                          alt=""
                        /> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 系统发出的事件类型的帖子  v-if="item.taskOrEventName" -->
              <div class="event-box" v-if="isEventTypePost(item)">
                <!-- 事件类型中签到中包含图片类型 -->
                <div class="event-file-img">
                  <div
                    class="fileImgs"
                    v-for="(fileitem, fileindex) in item.feedFile"
                    :key="fileindex"
                  >
                    <img
                      class="filetype-img"
                      @click="previewImg(fileitem.fileid)"
                      :src="`${imgUrlCookie}/chatterfile.action?m=preview&fileid=${
                        fileitem.fileid
                      }&binding=${tokenCookie}&dandan=${Math.random()}`"
                      alt=""
                    />
                  </div>
                </div>

                <div class="eventPost" v-if="isEventTypePost(item)">
                  <div class="name">
                    <svg
                      class="icon"
                      :class="
                        isjudgePostDelete(item) ? 'hand' : 'cancel-suspension'
                      "
                      style="outline: none"
                      aria-hidden="true"
                      @click="
                        isjudgePostDelete(item) &&
                          eventDetail(item.taskIdOrEventId)
                      "
                    >
                      <use :href="'#icon-' + showTpyeImg(item)"></use>
                    </svg>
                  </div>
                  <div
                    v-html="
                      isjudgePostDelete(item)
                        ? isEventTypePostTitle(item)
                        : item.body +
                          '  (' +
                          $t('vue_label_homepage_deleted') +
                          ')'
                    "
                    @click="
                      isjudgePostDelete(item) &&
                        eventDetail(item.taskIdOrEventId)
                    "
                    class="event-hand"
                    :class="
                      isjudgePostDelete(item) ? 'hand' : 'cancel-suspension'
                    "
                  ></div>
                </div>

                <div class="event-infor" v-if="isSeeMoreNews === index">
                  <!-- 邮件的发件人 -->
                  <div
                    class="email-message"
                    v-if="
                      item.emailIdOrSmsId &&
                      item.emailIdOrSmsId.substring(0, 3) === '025'
                    "
                  >
                    <div>{{ $t("label.senduser") }} :</div>
                    <div class="person">{{ item.emailFeed.fromaddress }}</div>
                  </div>
                  <!-- 邮件的收件人 -->
                  <div
                    class="email-message"
                    v-if="
                      item.emailIdOrSmsId &&
                      item.emailIdOrSmsId.substring(0, 3) === '025'
                    "
                  >
                    <div>{{ $t("label.addressee") }} :</div>
                    <div class="person">{{ item.emailFeed.toaddress }}</div>
                  </div>
                  <!-- 短信的创建时间 -->
                  <div
                    class="email-message"
                    v-if="
                      item.emailIdOrSmsId &&
                      item.emailIdOrSmsId.substring(0, 3) === '202'
                    "
                  >
                    <div>{{ $t("label.createdate") }} :</div>
                    <div class="person">
                      {{
                        item.smsFeed.createDate | yearMonthDayTime(countryCode)
                      }}
                    </div>
                  </div>
                  <!-- 内容 -->
                  <div class="infor-item">
                    <div>{{ $t("label.content") }} :</div>
                    <div
                      v-html="isEventTypePostCon(item)"
                      @click="
                        isjudgePostDelete(item) &&
                          eventDetail(item.taskIdOrEventId)
                      "
                      class="event-content"
                      :class="
                        isjudgePostDelete(item)
                          ? 'event-cont'
                          : 'cancel-suspension'
                      "
                    ></div>
                  </div>
                </div>
                <div class="see-more" v-if="isjudgePostDelete(item)">
                  <!-- 查看更多 -->
                  <div
                    @click="seeMoreNews(index)"
                    v-show="isSeeMoreNews !== index"
                  >
                    {{ $t("label_tabpage_lookatmorez") }}
                  </div>
                  <div
                    @click="noSeeMoreNews(index)"
                    v-show="isSeeMoreNews === index"
                  >
                    <!-- 关闭查看 -->
                    {{ $t("label.close") + $t("button_partner_viewdetail") }}
                  </div>
                </div>
              </div>

              <!-- 共享帖子的原始帖子 -->
              <div class="sharePost" v-if="item.orgauthorIdname">
                <!-- 帖子分享人信息 -->
                <div class="personLeft">
                  <div style="display: flex">
                    <img
                      @click="
                        enterDetailsPage(item.orgauthorId, item.orgauthorIdname)
                      "
                      :src="getHeadImg(item.orgauthorId)"
                      class="share-infor-img"
                    />
                  </div>
                  <div class="personName">
                    <div
                      class="posted"
                      @click="
                        enterDetailsPage(item.orgauthorId, item.orgauthorIdname)
                      "
                    >
                      {{ item.orgauthorIdname }}
                    </div>
                    <div>{{ item.createDate }}</div>
                  </div>
                </div>
                <div class="share-file-box">
                  <div style="display: flex; margin: 10px 20px 0 20px">
                    <!-- 被分享帖子的内容  v-show="showPostBody(item)" -->
                    <div
                      class="shareBody"
                      :class="!item.body ? 'clear-padding' : ''"
                    >
                      <p v-html="atToPersonDetail(item.body)"></p>
                    </div>
                    <!-- 被分享帖子的链接 -->
                    <div v-if="item.linkValue" class="sharePostLink">
                      <svg
                        class="sharePostLink"
                        aria-hidden="true"
                        v-if="item.linkValue"
                      >
                        <use href="#icon-website1"></use>
                      </svg>
                      <a :href="item.linkValue" v-html="item.linkName"> </a>
                    </div>
                  </div>

                  <!-- 查看原帖的文件 -->
                  <div
                    class="files"
                    v-if="
                      sharePostFileList.length > 0 &&
                      sharePostFileIndex === index
                    "
                  >
                    <div
                      v-for="(
                        sharefileitem, sharefileindex
                      ) in sharePostFileList[0].feedFile"
                      :key="sharefileindex"
                    >
                      <!-- 查看原帖文件中非图片类型文件 -->
                      <div
                        class="file-item"
                        style="margin-top: 10px"
                        v-if="!isYesImgType(sharefileitem.contentType)"
                      >
                        <div class="file-img">
                          <svg class="icon" aria-hidden="true">
                            <use href="#icon-uploadfile"></use>
                          </svg>
                          <div
                            v-show="!sharefileitem.filename"
                            style="margin-top: 6px; text-align: center"
                          >
                            <!-- 您没有权限查看该文件 -->
                            {{ $t("vue_label_chatter_no_permission") }}
                          </div>
                        </div>
                        <div class="file-name">
                          <div
                            class="name"
                            v-show="sharefileitem.filename"
                            v-html="
                              sharefileitem.filename +
                              '.' +
                              sharefileitem.contentType
                            "
                          ></div>
                          <svg
                            aria-hidden="true"
                            @click="
                              downLoadFile(sharePostFileList[0], sharefileindex)
                            "
                            v-show="sharefileitem.filename"
                          >
                            <use href="#icon-down"></use>
                          </svg>
                        </div>
                      </div>
                      <!--查看原帖文件中图片类型文件  -->
                      <div
                        v-if="isYesImgType(sharefileitem.contentType)"
                        class="original-share_img"
                      >
                        <img
                          @click="previewImg(sharefileitem.fileid)"
                          :src="`${imgUrlCookie}/chatterfile.action?m=preview&fileid=${
                            sharefileitem.fileid
                          }&binding=${tokenCookie}&dandan=${Math.random()}`"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="shareOriginal">
                  <!-- 判断是否可以出现展开原始帖子以及收起原帖 -->
                  <div
                    class="selectsee"
                    @click="lookOriginalPost(item, index)"
                    v-if="sharePostFileIndex !== index && isShowLookPost(item)"
                  >
                    {{ $t("vue_label_chatter_view_original_post") }}
                    <!--查看原帖  -->
                  </div>

                  <div>
                    {{
                      original(item)
                        ? original(item).length +
                          $t("label.item") +
                          " " +
                          $t("vue_label_chatter_comment")
                        : ""
                    }}
                  </div>
                </div>
                <!-- 原始帖子的评论-->
                <div
                  class="dialogueList-box"
                  v-for="(originalitem, originalindex) in originalPostcomments(
                    original(item),
                    index
                  )"
                  :key="originalindex"
                >
                  <div class="list">
                    <!-- 头像名字时间 -->
                    <div class="personLeft">
                      <div style="display: flex">
                        <img
                          :src="getHeadImg(item.authorId)"
                          style="outline: none; border-radius: 50%"
                        />
                      </div>
                      <div class="personName">
                        <div class="posted">
                          {{ originalitem.authorIdname }}
                        </div>
                        <div>{{ originalitem.createDate }}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="dialogueContent"
                    v-html="atToPersonDetail(originalitem.body)"
                  ></div>
                  <div class="dianzan">
                    <div
                      @click="praiseComment(item, originalitem, -1)"
                      v-if="originalitem.ispraised"
                    >
                      <svg class="icon dianzanlanImg" aria-hidden="true">
                        <use href="#icon-like"></use>
                      </svg>
                      <span
                        >{{
                          originalitem.praisenum && originalitem.praisenum > 0
                            ? originalitem.praisenum
                            : ""
                        }}
                      </span>
                    </div>
                    <div @click="praiseComment(item, originalitem, 1)" v-else>
                      <svg class="icon dianzanhuiImg" aria-hidden="true">
                        <use href="#icon-a-like-normal2"></use>
                      </svg>
                      <span
                        >{{
                          originalitem.originalitem &&
                          originalitem.praisenum > 0
                            ? originalitem.praisenum
                            : ""
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div>收起原帖</div> -->
              <div
                class="shou-post"
                v-if="sharePostFileIndex === index && isShowLookPost(item)"
                @click="collePctPost"
              >
                <!-- 收起原帖 -->
                {{ $t("vue_label_ccchat_closedetail") }}
              </div>
              <!-- 未投票的样式 -->
              <div
                class="vote-before"
                v-if="item.feedType === 'V' && !item.isPoll"
              >
                <el-radio-group v-model="voteRadio[index]">
                  <span
                    v-for="(pollsitem, pollsindex) in item.polls"
                    :key="pollsindex"
                  >
                    <el-radio :label="pollsitem.option">{{
                      pollsitem.option
                    }}</el-radio>
                  </span>
                </el-radio-group>
                <div class="see-vote">
                  <el-button
                    :disabled="voteRadio.length == 0 ? true : false"
                    :class="
                      voteRadio[index] || voteRadio[index] === 0
                        ? 'voteRadioActive'
                        : ''
                    "
                    @click="changePolls(item, voteRadio[index])"
                  >
                    <!-- 投票 -->
                    {{ $t("chatter.poll") }}
                  </el-button>
                  <div @click="seeVoteResult(item)">
                    <!-- 查看投票结果 -->
                    {{ $t("label.wei.result") }}
                  </div>
                </div>
              </div>
              <!-- 已经投票后样式 -->
              <div class="vote-after" v-if="item.isPoll">
                <div
                  class="vote-list"
                  v-for="(pollsitem, pollsindex) in item.polls"
                  :key="pollsindex"
                >
                  <div class="vote-percent">
                    <span>{{ pollsitem.option }}</span>

                    <el-progress
                      :percentage="Number(deletepercent(pollsitem.percentUser))"
                    >
                    </el-progress>
                  </div>
                  <div class="vote-num">( {{ pollsitem.pollUserNums }} )</div>
                </div>

                <div class="ammountVote">
                  <el-button @click="changeVote(item)">
                    {{ $t("chatter.changeVote") }}
                    <!-- 更改投票 -->
                  </el-button>
                  <div>
                    <!-- 共   -->
                    {{ $t("label.multilang.totalpage2") }}
                    {{ item.pollTotals ? item.pollTotals : "0" }}
                    <!-- 票 -->
                    {{ $t("chatter.vote") }}
                  </div>
                  <i
                    ref="loadingimg"
                    :class="refreshIndex === index ? 'loadimg' : ''"
                    class="el-icon-refresh-right refresh-class"
                    @click="refreshVote(item, index)"
                  ></i>
                </div>
              </div>
              <!-- 评论对话框 -->
              <div class="dialogue" v-if="currentComment === index">
                <div class="dialogue-box">
                  <div class="headImg">
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-head"></use>
                    </svg>
                  </div>
                  <div class="dialogueValue">
                    <!-- 撰写评论 -->
                    <textarea
                      v-model="commentValue"
                      @input="changInputValue"
                      style="
                        resize: none;
                        height: 72px;
                        width: 100%;
                        border-radius: 3px;
                        outline: none;
                        border: 1px solid #e3e2e2;
                      "
                      :placeholder="
                        $t('vue_label_chatter_write_review') + '...'
                      "
                    ></textarea>
                  </div>
                </div>

                <div class="fileLink">
                  <div class="left-box">
                    <div class="addOption"></div>
                  </div>
                  <el-button
                    type="primary"
                    size="mini"
                    @click="addMicroPostComments(item.id)"
                  >
                    {{ $t("component.sendmail.button.send") }}
                  </el-button>
                </div>
              </div>
              <!-- 评论列表 -->
              <div class="dialogueList" v-if="item.comments">
                <!-- 共享状态的帖子，共享内容在comments中和评论数据混合在一起，第一条就是共享数据，倒序后最后一条是共享内容的数据，遍历评论数据时候不显示共享数据 ，根据共享人orgauthorIdname来判断是否显示评论数据中的最后一条-->
                <!-- 根据是否是点击的评论来判断，点击的显示评论的条数，没有点击的都展示一条评论 -->
                <div
                  class="dialogueList-box"
                  v-for="(pinglunitem, pinglunindex) in item.comments"
                  :key="pinglunindex"
                  v-show="showCommentLength(item, index, pinglunindex)
                          && currentComment!=='' &&currentComment!=-1 "
                >
                  <div class="list">
                    <!-- 头像名字时间 -->
                    <div class="personLeft">
                      <div style="display: flex">
                        <img
                          @click="
                            enterDetailsPage(
                              pinglunitem.authorId,
                              pinglunitem.authorIdname
                            )
                          "
                          :src="getHeadImg(pinglunitem.authorId)"
                          style="
                            outline: none;
                            cursor: pointer;
                            border-radius: 50%;
                          "
                        />
                      </div>
                      <div class="personName">
                        <div
                          class="posted"
                          @click="
                            enterDetailsPage(
                              pinglunitem.authorId,
                              pinglunitem.authorIdname
                            )
                          "
                        >
                          {{ pinglunitem.authorIdname }}
                        </div>
                        <div>{{ pinglunitem.createDate }}</div>
                      </div>
                    </div>
                    <!-- 评论的编辑按钮 -->
                    <div
                      class="personRight"
                      v-if="item.isdelete || item.isedit || item.isfavorited"
                    >
                      <div class="dianzan">
                        <div
                          @click="praiseComment(item, pinglunitem, -1)"
                          v-if="pinglunitem.ispraised"
                        >
                          <svg class="icon dianzanlanImg" aria-hidden="true">
                            <use href="#icon-like"></use>
                          </svg>
                          <span
                            >{{
                              pinglunitem.praisenum && pinglunitem.praisenum > 0
                                ? pinglunitem.praisenum
                                : ""
                            }}
                          </span>
                        </div>
                        <div
                          @click="praiseComment(item, pinglunitem, 1)"
                          v-else
                        >
                          <svg class="icon dianzanhuiImg" aria-hidden="true">
                            <use href="#icon-a-like-normal2"></use>
                          </svg>
                          <span
                            >{{
                              pinglunitem.praisenum && pinglunitem.praisenum > 0
                                ? pinglunitem.praisenum
                                : ""
                            }}
                          </span>
                        </div>
                      </div>
                      <el-dropdown trigger="hover" @command="pinglunEditButton">
                        <div>
                          <svg class="icon dianzanhuiImg" aria-hidden="true">
                            <use href="#icon-xiala"></use>
                          </svg>
                        </div>
                        <el-dropdown-menu
                          slot="dropdown"
                          style="text-align: left"
                        >
                          <el-dropdown-item
                            class="dropdown"
                            v-if="pinglunitem.isedit"
                            :command="{
                              type: 'edit',
                              feedId: item.id,
                              pinglunitem: pinglunitem,
                            }"
                          >
                            <!-- 编辑 -->
                            {{ $t("label.weixin.detail.edit") }}
                          </el-dropdown-item>
                          <el-dropdown-item
                            class="dropdown"
                            v-if="pinglunitem.isdelete"
                            :command="{
                              type: 'del',
                              item: item,
                              pinglunitem: pinglunitem,
                            }"
                          >
                            <!-- 删除 -->
                            {{ $t("component_setup_tabs_label_delete") }}
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </div>
                  <div
                    class="dialogueContent"
                    v-html="atToPersonDetail(pinglunitem.body)"
                  ></div>
                </div>
                <!-- 查看更多评论 -->
                <div
                  v-if="showCommentBtn(item)&&currentComment===index"
                  class="moreDialogue"
                  @click="moreDialogue(item, index)"
                >
                  {{ $t("c1057") }}
                </div>
              </div>

              <!-- @功能组件 -->
              <at-List
                v-if="currentComment === index"
                :atListIsShow="atListIsShow"
                :problemValue="commentValue"
                @chooseNameValue="chooseNameValue"
              ></at-List>
            </div>
          </div>
        </div>
      </div>

      <!-- 所有数据 -->
    </div>
    <dynamicPreviewImg
      :PreviewImgId="PreviewImgId"
      @closePreviewImg="closePreviewImg"
      :isShowPreviewImg="isShowPreviewImg"
    ></dynamicPreviewImg>
  </div>
</template>

<script>
import {
  getCCChats,
  praiseFeed,
  addMicroPostComments,
  praiseComment,
  changePolls,
  favoriteFeed,
  viewFile,
} from "../api";
import dynamicPreviewImg from "@/views/colleagueCircle/components/dynamic-previewImg.vue";
import VueCookies from "vue-cookies";
import atList from "@/views/colleagueCircle/components/dynamic-atList.vue";

export default {
  components: { dynamicPreviewImg, atList },
  props: {
    // 区分是否首次点击查看更多
    isFirstShowMore: {
      type: String,
      default: "",
    },
    // 是否是PDF视图详情页
    isDetailType: {
      type: String,
      default: "",
    },
    // 判断是详情页还是同事圈
    postShowType: {
      type: String,
      default: "",
    },
    // 详情页当前选中活动还是动态
    activeName: {
      type: String,
      default: "",
    },
    // 选中小组的id
    changeGroupId: {
      type: String,
      default: "",
    },
    //滚动加载的页数
    postListPage: {
      type: Number,
      default: 0,
    },
    //详情页的记录id
    dataId: {
      type: String,
      default: "",
    },
    //是否清空帖子数据
    isClearData: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      atIndex: -1, //@出现的下标
      atListIsShow: false, //是否显示@列表
      eventImg: "cloudtab6_norm", //事件图标
      taskImg: "cloudtab5_norm", //任务图标
      messageImg: "message", //邮件图标
      emailImg: "email", //短信图标
      callImg: "callMe", //记录电话
      PreviewImgId: "", //图片预览id
      isShowPreviewImg: false, //是否显示预览图片
      fileImg: "", //图片路径
      tokenCookie: "", //token
      imgUrlCookie: "", //域名
      options: [
        //排序
        {
          value: "createDate",
          label: this.$i18n.t("component_chatter_label_title"), //最新发表
        },
        {
          value: "lastModifyDate",
          label: this.$i18n.t("chatter.new.at"), //"最新活动"
        },
      ],
      commentLength: 1, //默认显示1条评论
      commentValue: "", //评论内容
      selectData: "lastModifyDate", //按照时间排序
      searchAbstract: "", //搜索摘要
      feedsMaplist: [], //查询帖子获取的数据
      currentComment: -1, //当前评论
      editType: "", //编辑或者是删除的类型：帖子：post，评论：comment
      voteRadio: [], //投票单选按钮
      originalPost: "", //查看原始帖子
      isSeeMoreNews: false, //事件类型帖子点击查看更多消息
      pageSize: 0, //页数
      page: 0, //页数
      loading: false, //加载中
      queryType: "", //帖子类型
      feedId: "", //查询单个帖子的时候feedId
      refreshIndex: false, //当前点击的投票类型
      offtime: "", //查询时间
      targetId: "", //关联小组的id或者是记录id
      sharePostFileList: [], //点击查看原帖子文件信息
      sharePostFileIndex: "", //查看原帖文件显示位置
      newsFromType: this.$route.query.category, //消息弹框cchat类型进入同事圈
      newsPostId: this.$route.query.id, //消息跳转到同事圈的id
      moreCommentIndex: "", //当前点击的更多评论
      countryCode: this.$cookies.get("countryCode"),
      moreCommentLength:1,//点击查看更多评论时累加
    };
  },
  watch: {
    // 监听切换帖子
    postShowType: {
      handler() {
        //监听类型详情页类型是detailInfo，进入详情页不执行获取动态，活动切换动态的时候调取接口，
        if (this.postShowType != "detailInfo" && this.activeName != "first") {
          this.newsPostId = "";
          this.pageSize = 0;
          this.sharePostFileIndex = "";
          this.feedsMaplist = [];
          this.selectData = "lastModifyDate";
          this.offtime = "";
          this.searchAbstract = "";
          this.targetId = "";
          this.currentComment = ""; //切换帖子类型的时候关闭评论弹框
          this.commentValue = ""; //清空评论内容
          this.$emit("changepostListPage");
          //点小组的时候
          if (this.postShowType == "6" && this.changeGroupId) {
            return;
          } else if (this.postShowType == "") {
            return;
          } else if (
            //通知中心跳转小组的时候，默认类型是1且是小组跳转，阻止请求接口，清空跳转类型
            this.postShowType === "1" &&
            this.newsFromType === "ChatterGroup"
          ) {
            this.newsFromType = "";
            this.loading = false;
            return;
          } else if (
            //第一次从其他页面,通知中心跳转cchat的时候，默认类型是1且是cchat跳转
            this.postShowType === "1" &&
            this.newsFromType === "showmsg"
          ) {
            this.offtime = "";
            this.newsPostId = this.$route.query.id;
            this.feedsMaplist = [];
            this.getCCChats(this.pageSize);
          } else {
            this.getCCChats(this.pageSize);
          }
        }
      },
    },
    // 切换小组id
    changeGroupId: {
      handler(newval) {
        if (newval != "empty" && newval != "") {
          this.newsPostId = "";
          this.targetId = newval;
          this.sharePostFileIndex = "";
          this.pageSize = 0;
          this.selectData = "lastModifyDate";
          this.feedsMaplist = [];
          this.offtime = "";
          this.searchAbstract = "";
          this.getCCChats(this.pageSize);
        }
      },
      immediate: true,
    },
    // 父组件调用查询帖子接口
    isClearData(newval) {
      //编辑，删除，分享帖子或者评论
      if (newval === "refresh") {
        this.newsPostId = "";
        this.feedsMaplist = [];
        this.selectData = "lastModifyDate";
        this.searchAbstract = "";
        this.sharePostFileIndex = "";
        this.pageSize = 0;
        this.offtime = "";
        this.searchAbstract = "";
        this.currentComment = ""; //切换帖子类型的时候关闭评论弹框
        this.commentValue = ""; //清空评论内容
        this.getCCChats(this.pageSize,);
      } else if (newval === "loadmore" ) {
        if(this.isFirstShowMore!='three'&&this.isFirstShowMore!='firstmore'){
          this.getCCChats(this.postListPage);
        }
      } else if(newval === "hideHistory"){
        // 点击隐藏时数组截取前10条数据
        if(this.feedsMaplist&&this.feedsMaplist.length>10){
          this.feedsMaplist =this.feedsMaplist.slice(0, 10);
        }
        this.$emit("showSeeMore", true);
      }
      this.$emit("isClearDataChange");
    },
    //从其他页面跳转到某个帖子的时候不起作用，在同事圈页面，点击通知中心时生效，调取接口
    $route: function (to) {
      //监听到路由（参数）改变
      // 拿到目标参数 to.query.typeCode 去再次请求数据接口
      this.newsPostId = to.query.id;
      this.newsFromType = to.query.category;
      if (this.newsFromType === "showmsg" && this.newsPostId) {
        this.offtime = "";
        this.feedsMaplist = [];
        this.getCCChats(this.pageSize);
      }
    },
  },
  computed: {
    //查看原始帖子中的评论，判断是否有评论数据并且显示当前点击的帖子
    originalPostcomments() {
      return (data, index) => {
        if (data && index === this.originalPost) {
          return data;
        }
      };
    },
    // 判断事件类型，记录电话，邮件类型，任务类型和短信类型帖子是否删除
    isjudgePostDelete() {
      return (data) => {
        if (
          data.emailIdOrSmsId &&
          data.emailIdOrSmsId.substring(0, 3) === "202" &&
          data.smsFeed
        ) {
          // 短信类型未被删除
          return true;
        } else if (
          data.emailIdOrSmsId &&
          data.emailIdOrSmsId.substring(0, 3) === "202" &&
          !data.smsFeed
        ) {
          // 短信类型已被删除
          return false;
        } else if (
          data.emailIdOrSmsId &&
          data.emailIdOrSmsId.substring(0, 3) === "025" &&
          !data.emailFeed
        ) {
          // 邮件类型已被删除
          return false;
        } else if (
          data.emailIdOrSmsId &&
          data.emailIdOrSmsId.substring(0, 3) === "025" &&
          data.emailFeed
        ) {
          // 邮件类型未被删除
          return true;
        } else if (data.taskIdOrEventId && data.taskOrEventName) {
          // 事件类型未被删除
          return true;
        } else if (data.taskIdOrEventId && !data.taskOrEventName) {
          // 事件类型已被删除
          return false;
        }
      };
    },
    //根据帖子类型返回不同的事件图标，事件，短信，电子邮件图标
    showTpyeImg() {
      return (item) => {
        if (
          item.emailIdOrSmsId &&
          item.emailIdOrSmsId.substring(0, 3) === "202"
        ) {
          //短信类型
          return this.messageImg;
        } else if (
          item.emailIdOrSmsId &&
          item.emailIdOrSmsId.substring(0, 3) === "025"
        ) {
          //邮件类型
          return this.emailImg;
        } else if (item.taskOrEventName == "记录电话") {
          //记录电话
          return this.callImg;
        } else if (item.isTaskOrEvent === "1") {
          //任务类型
          return this.taskImg;
        } else if (item.isTaskOrEvent !== "1") {
          //事件类型
          return this.eventImg;
        }
      };
    },
    //判断是事件，电子邮件，短信类型帖子,返回不同类型的标题
    isEventTypePostTitle() {
      return (item) => {
        if (
          item.emailIdOrSmsId &&
          item.emailIdOrSmsId.substring(0, 3) === "202"
        ) {
          //短信类型
          return item.body;
        } else if (
          item.emailIdOrSmsId &&
          item.emailIdOrSmsId.substring(0, 3) === "025"
        ) {
          //邮件类型
          return item.body;
        } else if (item.taskIdOrEventId) {
          //事件和任务类型
          return item.taskOrEventName;
        }
      };
    },
    //判断是事件，电子邮件，短信类型帖子,返回不同内容
    isEventTypePostCon() {
      return (item) => {
        if (
          item.emailIdOrSmsId &&
          item.emailIdOrSmsId.substring(0, 3) === "202"
        ) {
          //短信类型
          return item.smsFeed.htmlbody;
        } else if (
          item.emailIdOrSmsId &&
          item.emailIdOrSmsId.substring(0, 3) === "025"
        ) {
          //邮件类型
          return item.emailFeed.htmlbody;
        } else if (item.taskIdOrEventId) {
          //事件和任务类型
          return item.body;
        }
      };
    },
    //判断是事件，任务，电子邮件，短信类型帖子
    isEventTypePost() {
      return (item) => {
        if (item.emailIdOrSmsId) {
          //邮件和短信类型
          return true;
        } else if (item.taskIdOrEventId) {
          //事件类型
          return true;
        } else {
          return false;
        }
      };
    },
    // 是否显示查看更多按钮
    showCommentBtn(){
      return (item) => {
        if(item?.comments?.length>this.moreCommentLength*5){
          return true;
        }else{
          return false;
        }
      }
    },
    //判断分享帖子的时候显示几条评论
    showCommentLength() {
      return (item, index, pinglunindex) => {
        //分享的帖子，切comments大于1是具有评论的,点击了显示多条评论
        if (
          item.feedId_original &&
          item.comments.length > 1 &&
          this.currentComment === index
        ) {
          return pinglunindex < this.commentLength;
          //分享的帖子，切comments大于1是具有评论的,未点击了显示1条评论
        } else if (
          item.feedId_original &&
          item.comments.length > 1 &&
          this.currentComment !== index
        ) {
          return pinglunindex < 1;
          //非分享类型帖子，点击当前的帖子
        } else if (!item.feedId_original && this.currentComment === index) {
          if(this.moreCommentLength>1&&item?.comments?.length){
            if(item?.comments?.length>this.moreCommentLength*5){
              return pinglunindex < this.moreCommentLength*5
            }else{
              return pinglunindex < item?.comments?.length
            }
          }else if(this.moreCommentLength==1 && item?.comments?.length>5){
            return pinglunindex < 5
          }else{
            return pinglunindex < item?.comments?.length
          }
        }else if (!item.feedId_original && this.currentComment !== index) {
          return false;
        }
      };
    },
    //查看原帖条件
    isShowLookPost() {
      //文件类型显示  //点赞大于0显示   //共享类型评论大于1 //普通类型评论大于0显示
      return (item) => {
        if (item.feedType === "D" && item.feedFile) {
          return true;
        } else if (item.praisenum > 0) {
          return true;
        } else if (item.feedId_original) {
          //original根据分享帖子id查询到原始帖子，返回评论数量
          if (this.original(item) && this.original(item).length > 0) {
            return true;
          } else {
            return false;
          }
        } else if (item.comments && !item.feedId_original) {
          return true;
        } else {
          return false;
        }
      };
    },
    // 判断是图片类型
    isYesImgType() {
      return (type) => {
        if (type === "JPG" || type === "jpg" || type === "png") {
          return true;
        } else if (type === "gif" || type === "svg" || type === "bmp") {
          return true;
        } else if (type === "jpeg" || type === "tif") {
          return true;
        } else {
          return false;
        }
      };
    },
    // 判断不是图片类型
    isImgType() {
      return (type) => {
        if (type === "JPG" || type === "jpg" || type === "png") {
          return false;
        } else if (type === "gif" || type === "svg" || type === "jpeg") {
          return false;
        } else if (type === "tif" || type === "bmp") {
          return false;
        } else {
          return true;
        }
      };
    },
    // 判断帖子类型
    postType() {
      return (data) => {
        if (data.feedType === "L") {
          return this.$i18n.t("chatter.feed.publishfeed"); //"发表张贴"
        } else if (data.feedType === "D") {
          return this.$i18n.t("chatter.feed.publishfeed"); //"发表张贴"
        } else if (data.feedType === "G") {
          return this.$i18n.t("vue_label_ccchat_notice_updaterecordsuccess"); //"已更新此记录";
        } else if (data.feedType === "V") {
          return this.$i18n.t("label.weixin.avote"); // "发起投票";
        } else {
          return this.$i18n.t("chatter.feed.publishfeed"); //"发表张贴"
        }
      };
    },
    /**
     * 显示帖子内容
     * @returns {String} 换回内容
     */
    showPostBody() {
      return (item) => {
        if (item.feedId_original) {
          //存在分享id就是分享类型的帖子，分享类型的当前帖子body在comments[comments.length].body里面,数据倒序
          if (item.comments) {
            return item.comments[item.comments.length - 1].body;
          } else if (item.commentsForMobile) {
            //手机端
            return item.commentsForMobile[item.commentsForMobile.length - 1]
              .body;
          }
        } else if (!item.feedId_original && item.body) {
          //除分享类型帖子其他body均在item.body
          return item.body;
        }
      };
    },
    // 查找原始帖子的评论数据，根据分享帖子id去数据中查找原始帖子评论数量
    original() {
      return (data) => {
        //判断是否为分享类型帖子
        if (data.feedId_original) {
          for (var i = 0; i < this.feedsMaplist.length; i++) {
            if (
              //分享类型帖子在数据中查找原帖，且原帖不为分享类型帖子直接返回评论数据
              data.feedId_original === this.feedsMaplist[i].id &&
              !this.feedsMaplist[i].feedId_original
            ) {
              return this.feedsMaplist[i].comments;
            } else if (
              //分享类型帖子在数据中查找原帖，且原帖为分享类型帖子，使用原帖的的分享帖子id再次进行查找
              data.feedId_original === this.feedsMaplist[i].id &&
              this.feedsMaplist[i].feedId_original
            ) {
              this.original(this.feedsMaplist[i]);
            }
          }
        }
      };
    },
  },
  mounted() {
    //token
    this.tokenCookie = VueCookies.get("JSESSIONID");
    //域名
    this.imgUrlCookie = VueCookies.get("domainName");
    if (this.postShowType === "1" && !this.newsPostId) {
      this.getCCChats(this.pageSize);
    } else if (this.newsPostId) {
      this.getCCChats(this.pageSize);
    }
  },
  methods: {
    // PDF视图详情页根据不同状态展示数据
    showDataLength(data) {
      let newData = [];
      if (this.isFirstShowMore == "three") {
        data.map((item, index) => {
          if (index < 3) {
            newData.push(item);
          }
        });
        return newData;
      } else if (this.isFirstShowMore == "firstmore") {
        return data;
      } else {
        return data;
      }
    },
    // 监听输入框的值的变化,出现@调取组件
    changInputValue() {
      this.atIndex = this.commentValue.lastIndexOf("@");
      if (this.commentValue.indexOf("@") > -1) {
        this.atListIsShow = true;
      }
    },
    // 选中@列表后返回某人
    chooseNameValue(value) {
      this.atListIsShow = false;
      if (this.commentValue != "") {
        this.commentValue =
          this.commentValue.slice(0, this.atIndex + 1) + "[" + value + "]";
      }
    },
    //获取人员头像
    getHeadImg(id) {
      return `${
        this.imgUrlCookie
      }/chatterzone.action?m=showPhoto&userid=${id}&binding=${
        this.tokenCookie
      }&dandan=${Math.random()}`;
    },
    //@某个人的时候，接口返回数据带有a标签中带有地址，修改跳转地址
    atToPersonDetail(value) {
      if (value) {
        var address = value.replace(
          /chatterzone.action\?m=zoneTab&userid=/g,
          "#/commonObjects/detail/"
        );
        var index = address.indexOf("detail");
        var newaddress = address;
        if (index > -1) {
          // 字符出现的位置 +27
          while (index > -1) {
            newaddress =
              newaddress.slice(0, index + 27) +
              "/DETAIL" +
              newaddress.slice(index + 27);
            index = newaddress.indexOf("detail", index + 28); // 从字符串出现的位置的下一位置开始继续查找
          }
          return newaddress;
        } else {
          return value;
        }
      } else {
        return "";
      }
    },
    //图片预览
    previewImg(id) {
      this.isShowPreviewImg = true;
      this.PreviewImgId = id;
    },
    closePreviewImg() {
      this.isShowPreviewImg = false;
    },
    // 事件类型帖子跳转到详情页
    eventDetail(id) {
      if (id) {
        let routeData = this.$router.resolve({
          path: `/commonObjects/detail/${id}/DETAIL`,
        });
        window.open(routeData.href, "_blank");
      }
    },
    // 文件预览
    async viewFile(id) {
      let pararm = {
        id: id, //文件id
      };
      let res = await viewFile(pararm);
      if (res.result) {
        return res.data.previewurl;
      }
    },
    //收起原帖
    collePctPost() {
      this.sharePostFileIndex = "";
      this.originalPost = "";
    },
    // 查看原始帖子,查询某个帖子看是否存在
    async lookOriginalPost(item, index) {
      let pararm = {
        queryType: "findone", //type, //company,record,homepage
        feedId: item.feedId_original,
        skip: 0,
        limit: 5,
      };
      let res = await getCCChats(pararm);
      if (res.result && res.data.feedsMaplist.length > 0) {
        this.sharePostFileList = res.data.feedsMaplist;
        this.sharePostFileIndex = index;
        this.originalPost = index;
      } else {
        // this.$message.info("帖子已被删除");
        this.$message.info(this.$i18n.t("vue_label_chatter_post_has_delete"));
        this.sharePostFileIndex = "";
        this.originalPost = "";
      }
    },
    // 下载文件
    downLoadFile(item, fileindex) {
      let baseURL = this.$baseConfig.baseURL;
      var token = VueCookies.get("JSESSIONID");
      var link = document.createElement("a");
      link.setAttribute("download", "");
      var fileid = {};
      var index = item.fileInfoId.indexOf(",");
      if (index > -1) {
        fileid = item.fileInfoId.split(",");
      } else {
        fileid = item.fileInfoId;
      }
      link.href = `${baseURL}/file/downloadFile?accessToken=${token}&id=${
        index > -1 ? fileid[fileindex] : fileid
      }`;
      link.click();
    },
    // 进入同事圈详情页
    enterDetailsPage(id, name, data) {
      if (data.targetType != "group") {
        this.$router.push({
          path: `/commonObjects/detail/${id}/DETAIL`,
        });
      }
    },
    // 收藏，点赞，评论对数据进行更新
    updateData(value) {
      var data = value;
      var index = this.feedsMaplist.findIndex((item) => item.id === data.id);
      if (data.comments) {
        data.comments.reverse();
      }
      this.feedsMaplist[index] = data;
      this.$forceUpdate();
    },

    // 刷新投票类型帖子
    async refreshVote(data, index) {
      this.refreshIndex = index;
      this.feedId = data.id;
      this.queryType = "findone";
      let pararm = {
        feedId: this.feedId,
        queryType: this.queryType,
        limit: 10,
        skip: 0,
      };
      let res = await getCCChats(pararm);
      if (res.result) {
        if (res.data.feedsMaplist.length > 0) {
          this.updateData(res.data.feedsMaplist[0]);
        }
        this.refreshIndex = false;
      } else {
        this.$message.info(this.$i18n.t("chatter.error.refresherror"));
        // this.$message.info("刷新失败");
        this.refreshIndex = false;
      }
    },
    // 事件类型点击查看更多消息
    seeMoreNews(index) {
      this.isSeeMoreNews = index;
    },
    // 事件类型关闭查看更多消息
    noSeeMoreNews() {
      this.isSeeMoreNews = false;
    },
    //查询帖子接口,//请求页数，详情页的查询类型只有两个：record，zone
    async getCCChats(postListPage) {
      this.loading = true;
      var userId = ""; //用户id
      var recordId = ""; //记录id
      if (this.newsPostId && this.newsFromType === "showmsg") {
        this.queryType = "findone";
        this.targetId = "";
        this.feedId = this.newsPostId;
      } else if (this.queryType === "zone") {
        //个人详情页获取数据
        userId = this.dataId;
      } else if (this.queryType === "record") {
        // 详情页获取数据
        recordId = this.dataId;
      } else {
        if (this.postShowType === "1") {
          this.queryType = "news"; //关注homepage
          this.targetId = "";
        } else if (this.postShowType === "2") {
          this.queryType = "me"; //涉及
          this.targetId = "";
        } else if (this.postShowType === "3") {
          this.queryType = "mark"; //收藏mark
          this.targetId = "";
        } else if (this.postShowType === "4") {
          this.queryType = "company"; //所有
          this.targetId = "";
        } else if (this.postShowType === "5" || this.postShowType === "6") {
          //点击小组是5，切换小组id是6
          this.queryType = "group"; //小组
        }
      }
      let pararm = {
        queryType: this.queryType, //type, //company,record,homepage
        commentId: "",
        userId: userId,
        feedId: this.feedId ? this.feedId : "",
        recordId: recordId, //
        feedSort: this.selectData ? this.selectData : "", //排序
        limit: 10,
        skip: postListPage,
        targetId: this.targetId != "empty" ? this.targetId : "", //小组记录id或者关联记录id
        offtime: this.offtime ? this.offtime : "",
        chatKeywords: this.searchAbstract ? this.searchAbstract : "", //搜索关键字
        topicId: "",
        feedSatus: "",
      };
      this.$emit("stopLoadMorePostListEvent", true);
      if (this.postShowType == "") {
        return;
      } else {
        let res = await getCCChats(pararm);
        this.refreshIndex = false;
        if (res.result) {
          if (res.data.feedsMaplist.length > 0) {
            // 首次请求清空数据
            if (postListPage == 0) {
              this.feedsMaplist = [];
            }
            res.data.feedsMaplist.map((item) => {
              if (item.comments) {
                item.comments.reverse();
              } else if (item.commentsForMobile) {
                item.commentsForMobile.reverse();
              }
            });
            // 首次进入PDF页面，或点击隐藏历史记录按钮，仅展示前三条数据
            res.data.feedsMaplist.map((item) => {
              this.feedsMaplist.push(item);
            });
            //当feedsMaplist=10时，再次调取接口判断是否还有数据
            // if (postListPage == 0 && res.data.feedsMaplist.length >3) {
            //   this.$emit("showSeeMore", true);
            // } else
            if (
              res.data.feedsMaplist.length >3
            ) {
              this.againCcarts(pararm);
            } else {
              this.$emit("showSeeMore", false);
            }
            this.$forceUpdate();
            this.loading = false;
            this.feedId = "";
            // 如果是详情页和个人详情页就不清空类型
            if (this.queryType != "zone" && this.queryType != "record") {
              this.queryType = "";
            }

            this.offtime = res.data.offtime;
            // 返回数据小于5条
            if (res.data.feedsMaplist.length < 10) {
              // 首次进入不显示提示没有更多数据
              if (postListPage > 10) {
                this.$message.info(this.$i18n.t("label.weixin.nomore"));
              }
              this.$emit("stopLoadMorePostListEvent", true);
            } else {
              //根据操作：判断调取接口是滚动加载还是重新从0请求接口
              setTimeout(() => {
                this.$emit("stopLoadMorePostListEvent", false);
              }, 1000);
              this.$emit("isClearDataChange");
            }
          } else {
            this.$emit("showSeeMore", false);
            //没有数据就停止滚动加载
            this.$emit("stopLoadMorePostListEvent", true);
            // 如果已经在滚动，且无数据，才提示没有更多数据了。第一次加载无数据不需提示。
            if (postListPage > 10) {
              // this.$message.info("没有更多数据了");
              this.$message.info(this.$i18n.t("label.weixin.nomore"));
            }
            this.loading = false;
            this.feedId = "";
            // 如果是详情页和个人详情页就不清空类型
            if (this.queryType != "zone" && this.queryType != "record") {
              this.queryType = "";
            }
          }
        } else {
          this.loading = false;
        }
      }
    },
    // 测试是否显示查看更多按钮
    async againCcarts(params) {
      let againParams = JSON.parse(JSON.stringify(params));
      if (againParams.limit == 10) {
        this.$set(againParams, "skip", againParams.skip + 10);
      }
      this.$set(againParams, "limit", 11);
      let res = await getCCChats(againParams);
      if (res.data.feedsMaplist?.length > 10) {
        this.$emit("showSeeMore", true);
      }else if(this.isFirstShowMore=='three' && res.data.feedsMaplist.length==0){
        this.$emit("showSeeMore", false);
      }else if(this.isFirstShowMore=='three' && this.feedsMaplist.length>3){
        this.$emit("showSeeMore", true,);
      } else {
        this.$emit("showSeeMore", false);
      }
    },
    // 刷新按钮
    refreshData() {
      this.refreshIndex = true;
      this.feedsMaplist = [];
      this.selectData = "lastModifyDate";
      this.searchAbstract = "";
      this.offtime = "";
      this.getCCChats(this.pageSize);
    },
    // 更多评论，默认显示两条评论，点击更多评论显示所有评论
    moreDialogue(data, index) {
      //点击查看更多评论默认显示5条每次点击默认增加5条
      if(this.moreCommentIndex == index){
        if(data.feedId_original && this.moreCommentLength*5<data.comments.length - 1){
          this.moreCommentLength+=1;
        }else if(!data.feedId_original && this.moreCommentLength*5<data.comments.length){
          this.moreCommentLength+=1;
        }
      }else{
        this.moreCommentLength=1;
      }
      this.moreCommentIndex = index;
      if (this.commentLength === 1) {
        //根据是否具有分享帖子id判断是不是分享类型帖子，是的话评论数量-1，否则显示comments的数量
        if (data.feedId_original) {
          this.commentLength = data.comments.length - 1;
        } else {
          this.commentLength = data.comments.length;
        }
      } else if (this.commentLength != 1) {
        this.commentLength = 1;
      }
    },
    //去掉百分号
    deletepercent(val) {
      if (val) {
        return val.slice(0, val.length - 1) * 1;
      }
    },
    //点击评论获取当前的index
    commentButton(index) {
      if (this.currentComment === index) {
        this.commentValue = ""; //清空评论内容
        this.currentComment = "";
        this.moreCommentLength = 1;
      } else {
        this.currentComment = index;
        this.commentValue = ""; //清空评论内容
      }
    },

    // 与小组成员分享帖子
    shareButton(command) {
      this.$emit("shareButton", command.data, command.type);
    },

    //投票帖子
    async changePolls(item, pollvalue) {
      var index = "";
      for (let i = 0; i < item.polls.length; i++) {
        if (pollvalue === item.polls[i].option) {
          index = i;
        }
      }
      var params = {
        feedId: item.id,
        optionValue: index,
      };
      let res = await changePolls(params);
      if (res.result) {
        if (res.data.feedsMaplist.length > 0) {
          this.updateData(res.data.feedsMaplist[0]);
        }
        this.voteRadio = [];
      }
    },
    //评论帖子
    async addMicroPostComments(id) {
      if (this.commentValue) {
        var params = {
          feedId: id,
          body: this.commentValue.trim(),
        };
        let res = await addMicroPostComments(params);
        if (res.result) {
          this.currentComment = "";
          this.commentValue = "";
          if (res.data.feedsMaplist.length > 0) {
            if (res.data.feedsMaplist.length > 0) {
              this.updateData(res.data.feedsMaplist[0]);
            }
          }
        }
      } else {
        // this.$message.info("请输入评论内容");
        this.$message.info(this.$i18n.t("vue_label_chatter_comment_content"));
      }
    },
    //搜索框摘要,当清空搜索框时，恢复全部数据
    searchChangeDynamic() {
      if (this.searchAbstract === "") {
        this.feedsMaplist = [];
        this.selectData = "lastModifyDate";
        this.offtime = "";
        this.getCCChats(this.pageSize);
      }
    },
    //搜索框摘要
    searchEnterDynamic() {
      if (this.searchAbstract) {
        this.feedsMaplist = [];
        this.currentComment = "";
        this.commentValue = ""; //清空评论内容
        this.selectData = "lastModifyDate";
        this.offtime = "";
        this.getCCChats(this.pageSize);
      }
    },
    // 清空搜索关键词重新请求接口
    clearSearch() {
      this.feedsMaplist = [];
      this.selectData = "lastModifyDate";
      this.offtime = "";
      this.searchAbstract = "";
      this.getCCChats(this.pageSize);
    },
    //选择框按照最新活动或者最新发表排序
    changeOption() {
      this.feedsMaplist = [];
      this.offtime = "";
      this.getCCChats(this.pageSize);
    },
    // 更改投票
    changeVote(item) {
      this.feedsMaplist.filter((itemy, indexy) => {
        itemy.id === item.id && (this.voteRadio[indexy] = item.pollvalue);
      });
      item.isPoll = false;
      this.$forceUpdate();
    },
    // 查看投票结果
    seeVoteResult(item) {
      item.isPoll = true;
      this.$forceUpdate();
    },
    // 帖子点赞
    async praiseFeed(value, num) {
      var params = {
        feedId: value.id,
        likeOrDislike: num,
      };
      let res = await praiseFeed(params);
      if (res.result) {
        if (res.data.feedsMaplist.length > 0) {
          this.updateData(res.data.feedsMaplist[0]);
        }
      }
    },
    //评论点赞
    async praiseComment(item, value, num) {
      var params = {
        feedId: item.id,
        commentId: value.id,
        likeOrDislike: num,
      };
      let res = await praiseComment(params);
      if (res.result) {
        if (res.data.feedsMaplist.length > 0) {
          this.updateData(res.data.feedsMaplist[0]);
        }
      }
    },
    // 收藏帖子
    async favoriteFeed(id, num) {
      var params = {
        feedId: id,
        markFlag: num,
      };
      let res = await favoriteFeed(params);
      if (res.result) {
        this.updateData(res.data.feedsMaplist[0]);
      }
    },

    // 帖子编辑按钮：编辑收藏删除
    editButton(command) {
      this.editType = "post"; //记录当前要操作的类型是帖子还是评论
      if (command.type == "edit") {
        this.$emit("editPost", command.item, this.editType);
      } else if (command.type == "del") {
        this.$emit("delPost", command.item, this.editType);
      } else if (command.type == "collection") {
        this.favoriteFeed(command.item.id, command.islike);
      }
    },
    //对评论的操作：编辑删除
    pinglunEditButton(command) {
      this.editType = "comment";
      if (command.type == "edit") {
        this.$emit(
          "commentEditPost",
          command.feedId,
          command.pinglunitem,
          this.editType
        );
      } else if (command.type == "del") {
        this.$emit("delPost", command.pinglunitem, this.editType);
      }
    },
  },
};
</script>
<style lang="scss">
.voteRadioActive,
.voteRadioActive:hover,
.voteRadioActive:focus {
  background: #1b5297 !important;
  color: #fff;
}

.el-select-dropdown__item {
  font-size: 12px;
}
.el-dropdown-menu__item {
  font-size: 12px;
}
</style>
<style lang="scss" scoped>
.cancel-suspension {
  cursor: default;
}
.event-hand {
  padding: 10px 10px 10px 0;
}
.clear-padding {
  padding: 0 !important;
}
.line-border {
  border: 1px solid #dcdfe6;
  padding: 0 8px;
  border-radius: 3px;
  height: 30px;
}
.recordName {
  cursor: pointer;
  flex-wrap: wrap;
  word-break: break-all;
}
.noCursor {
  cursor: default;
  flex-wrap: wrap;
  word-break: break-all;
}
.hand {
  cursor: pointer;
}

.event-cont {
  color: #006dcc !important;
  cursor: pointer;
}
.event-content {
  ::v-deep p img {
    width: 120px !important;
    height: 92px !important;
    max-width: 120px !important;
  }
}
.dropdown {
  // width: 100px;
}
.dropdownshare {
  width: 150px;
}
.share-infor-img {
  outline: none;
  margin-top: 5px;
  cursor: pointer;
  width: 26px;
  height: 26px;
  border-radius: 50%;
}
@keyframes loadimg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loadimg {
  animation: loadimg 2s linear infinite;
}
.share-group-like {
  display: flex;
  align-items: center;
  vertical-align: middle;
}
.shou-post {
  cursor: pointer;
  margin-top: 5px;
  color: #006dcc;
  cursor: pointer;
  margin-bottom: 10px;
  text-align: center;
}

::v-deep .lightChatContent {
  color: #006dcc !important;
}
// 共享文件中的图片
.original-share_img {
  display: flex;
  img {
    width: 120px;
    height: 100px;
    margin: 10px 20px 10px 0;
  }
}
.dynamic {
  padding: 0;
  min-height: 400px;
  margin-top: 25px;
  ::v-deep .el-loading-mask {
    z-index: 100;
  }
  //排序搜索样式
  .header-box {
    margin-right: 30px;
    display: flex;
    // margin-bottom: 20px;
    .sort {
      flex: 1;
      font-size: 12px;
    }

    .select {
      width: 200px;
      margin-left: 10px;
      height: 30px;
      ::v-deep .el-input__inner,
      ::v-deep .el-input--suffix .el-input__inner,
      ::v-deep .el-input__icon {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
      }
    }
    .search {
      width: 200px;
      height: 30px;
      margin-right: 10px;
      ::v-deep .el-input--suffix .el-input__inner,
      ::v-deep .el-input__icon,
      ::v-deep .el-input__inner,
      .el-input__icon {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
      }
    }
    i {
      height: 30px;
      line-height: 30px;
      cursor: pointer;
    }
  }
  // 事件类型的帖子样式
  .event-box {
    .event-file-img {
      display: flex;
      flex-wrap: wrap;
    }
    .eventPost {
      display: flex;
      border-radius: 3px;
      border: 1px solid #dddbda;
      margin: 10px 0;

      .name {
        display: flex;
        svg {
          margin: 10px 10px 10px 10px;
          width: 18px;
          height: 18px;
        }
      }
    }
    .see-more {
      // height: 30px;
      // line-height: 30px;
      text-align: center;
      color: #006dcc;
      cursor: pointer;
    }
    .event-infor {
      display: flex;
      border: 1px solid #dddbda;
      border-radius: 3px;
      padding: 0 10px;
      flex-direction: column;
      margin-top: -10px;
      margin-bottom: 10px;
      .infor-item {
        display: flex;
        flex-direction: column;
        margin: 5px 0;
        max-height: 100px;
        overflow-y: auto;
        // margin-bottom: 5px;
      }
      .email-message {
        display: flex;
        margin: 5px 0;
        .person {
          margin-left: 5px;
        }
      }
    }
  }
  .title-box {
    display: flex;
    flex-direction: column;
    .title-img {
      margin: 100px auto 30px;
      svg {
        width: 200px;
        height: 120px;
      }
    }
    .title {
      text-align: center;
      color: #666666;
    }
  }

  // 点赞评论样式
  // .comment {
  .comment {
    margin-top: 10px;
    font-size: 12px;
    .timeline-box {
      .timeline-item {
        display: flex;
        position: relative;
      }
      .timeline-time {
        width: 135px;
        text-align: right;
      }
      .timeline-icon {
        width: 30px;
        position: relative;
        left: 20px;
        svg {
          width: 18px;
          height: 18px;
        }
      }
      .timeline-content {
        width: calc(100% - 135px);
        border-left: 1px solid #e1e1e1;
        padding-left: 20px;
      }
      .timeline-item:last-child {
        .timeline-content {
          border-left: none;
        }
      }
    }
  }
  .Post {
    margin-right: 30px;
    padding-bottom: 19px;
  }
  .start {
    margin-right: 18px;
    margin-bottom: 2px;
    img,
    svg {
      width: 14px;
      height: 14px;
    }
  }
  .sharePost {
    display: flex;
    border-radius: 3px;
    border: 1px solid #dddbda;
    margin-bottom: 10px;
    // margin-bottom: 20px;
    flex-direction: column;
    .personLeft {
      display: flex;
      margin-left: 18px;
      margin-top: 10px;
      img {
        width: 24px;
        margin-right: 12px;
        // margin-top: 8px;
      }
      .personName {
        margin-right: 6px;
        color: #666666;
      }
      .publish {
        color: #080707;
      }
    }
    .sharePostLink {
      display: flex;
      vertical-align: middle;
      align-items: center;
    }
    .sharePostImg {
      width: 14px;
      height: 14px;
      margin-right: 6px;
    }
    .shareBody {
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      div {
        margin-left: 10px;
      }
      img {
        width: 14px;
        height: 14px;
        margin-bottom: 2px;
        display: inline-block;
      }
      a {
        font-size: 14px;
        margin-left: 6px;
        height: 15px;
      }
      p {
        margin: 0;
      }
    }
    .shareOriginal {
      display: flex;
      padding: 0 20px 10px 20px;
      .selectsee {
        cursor: pointer;
        margin-top: 5px;
        color: #006dcc;
        cursor: pointer;
      }
      div:last-child {
        flex: 1;
        text-align: right;
      }
    }
    .dialogueList-box {
      background: #F9F9F9;
      .dialogueContent {
        margin-left: 18px;
        padding-bottom: 8px;
      }
      .dianzan {
        display: flex;
        margin: 0 0 10px 18px;
        div {
          vertical-align: middle;
          align-items: center;
          display: flex;
          cursor: pointer;
          .dianzanhuiImg,
          .dianzanlanImg {
            margin-right: 6px;
            width: 13px;
            height: 13px;
          }
          span {
            margin-right: 30px;
          }
        }
      }
    }
  }
  .posted {
    cursor: pointer;
  }
  .personInfor {
    .posted {
      margin-right: 37px;
    }
  }
  .personInfor,
  .list {
    display: flex;
    .personLeft {
      flex: 1;
      display: flex;
      align-content: center;
      align-items: center;
      img {
        margin-right: 12px;
        width: 24px;
        height: 24px;
      }
      .personName {
        margin-right: 6px;
        color: #666666;
      }
      .publish {
        color: #080707;
      }
    }
    .personRight {
      display: flex;
      min-width: 200px;
      justify-content: end;
      align-content: center;
      align-items: center;
      .el-icon-caret-bottom {
        cursor: pointer;
      }
      ::v-deep .el-dropdown-menu {
        width: 100px !important;
      }
    }
  }
  // }
  //   帖子
  .postContent {
    display: flex;
    padding: 5px 0 8px;
    flex-direction: column;
    .postBody {
      display: flex;
      margin-right: 10px;
      p {
        margin-bottom: 0;
        flex-wrap: wrap;
        word-break: break-all;
      }
    }
    .link {
      display: flex;
      align-items: center;
      vertical-align: middle;
      // margin-left: 10px;
      cursor: pointer;
      img {
        width: 14px;
        height: 14px;
        display: inline-block;
      }
      a {
        font-size: 12px;
        margin-left: 6px;
      }
    }
  }

  .fileTextImg {
    display: flex;
    width: 220px;
  }
  .fileText {
    display: flex;
  }
  .pdffileImgs {
    cursor: pointer;
    color: #2d6cfc;
  }
  .fileImgs {
    display: flex;
    .filetype-img {
      width: 120px;
      height: 92px;
      margin: 10px 20px 10px 0;
    }
  }
  // 文件帖子的样式
  .share-file-box {
    vertical-align: middle;
    .files {
      margin: 0 20px;
    }
  }
  .file-box,
  .share-file-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    .files {
      display: flex;
      flex-wrap: wrap;
    }
    .file-item {
      display: flex;
      justify-content: space-between;
      margin-right: 20px;
      .file-nodata {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        img,
        svg {
          width: 16px;
          height: 14px;
          display: inline-block;
          margin: 0 auto;
          margin-right: 12px;
        }
      }
    }
    .file-name {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      vertical-align: middle;
      height: 26px;
      .name {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 10px;
      }

      svg,
      img {
        width: 15px;
      }
    }
  }
  // 投票前
  .vote-before {
    border-radius: 3px;
    border: 1px solid #dddbda;
    margin-bottom: 20px;
    // height: 160px;
    .el-radio {
      width: 100%;
      margin: 20px 20px 0px;
    }
    .see-vote {
      display: flex;
      margin: 10px 20px;
      vertical-align: middle;
      align-items: center;
      .el-button {
        width: 64px;
        height: 26px;
        padding: 0;
        margin-right: 10px;
        border-radius: 3px;
        font-size: 12px;
      }
      div {
        cursor: pointer;
        font-size: 12px;
        font-weight: 400;
        color: #006dcc;
      }
    }
  }
  // 投票后
  .vote-after {
    // height: 200px;
    // display: flex;
    border-radius: 3px;
    border: 1px solid #dddbda;
    margin-bottom: 20px;

    .vote-list {
      padding: 15px 20px 5px;
      display: flex;
      // flex-direction: column;
      .vote-percent {
        // display: flex;
        // flex-direction:row;
        flex: 1;
      }
      .vote-num {
        display: flex;
        width: 10%;
        align-items: flex-end;
        font-size: 12px;
      }
    }
    .ammountVote {
      padding: 15px 20px;
      display: flex;
      vertical-align: middle;
      align-items: center;
      .el-button,
      .el-button:hover,
      .el-button:focus {
        margin-right: 10px;
        height: 26px;
        padding: 0 5px;
        font-size: 12px;
        border-radius: 3px;
        background: #1b5297 !important;
        color: #fff;
      }
      i {
        cursor: pointer;
      }
      div {
        margin-right: 20px;
      }
    }
  }
  .give-comment {
    display: flex;
    height: 19px;
    .share {
      font-size: 12px;
    }
    div {
      vertical-align: middle;
      align-items: center;
      display: flex;
      cursor: pointer;
      margin-right: 20px;
      .dianzanlanImg,
      .dianzanhuiImg,
      .fenxianghuiImg,
      .pinglunhuiImg {
        margin-right: 6px;
        width: 13px;
        height: 13px;
      }
      span {
        display: inline-block;
      }
    }
    .numcomment {
      flex: 1;
      justify-content: flex-end;
      margin-right: 20px;
    }
  }
  // 评论列表
  .dialogueList {
    display: flex;
    flex-direction: column;
    background: #F9F9F9;
    margin-top: 5px;
    .moreDialogue {
      color: #006dcc;
      padding: 0 10px 10px;
      cursor: pointer;
      text-align: center;
    }
    .list {
      margin: 8px 0 0 12px;
      .personLeft {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        align-content: center;
        img {
          width: 24px;
        }
      }
      .personName {
        display: flex;
        align-content: center;
        align-items: center;
        .posted {
          margin-right: 44px;
        }
      }
      // .personRight {
      //   margin-top: 24px;
      // }
      .personRight {
        margin-right: 10px;
      }
    }
    .dialogueContent {
      margin-left: 18px;
      padding-bottom: 8px;
    }
    .dianzan {
      display: flex;
      div {
        vertical-align: middle;
        align-items: center;
        display: flex;
        cursor: pointer;
        .dianzanhuiImg,
        .dianzanlanImg {
          margin-right: 6px;
          width: 13px;
          height: 13px;
        }
        span {
          margin-right: 30px;
        }
      }
    }
  }
  //评论对话框
  .dialogue {
    height: 150px;
    width: 100%;
    background: #F9F9F9;
    display: flex;
    flex-direction: column;

    .dialogue-box {
      display: flex;
      flex-direction: row;
      .dialogueValue {
        flex: 1;
        margin-top: 17px;
        margin-right: 12px;
      }
      .headImg {
        background: #F9F9F9;
        width: 20px;
        outline: none;
        margin: 17px 10px 0 12px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .fileLink {
      display: flex;
      flex-direction: row;
      margin: 10px 18px 0 48px;
      .left-box {
        .addOption {
          color: #006dcc;
          font-size: 12px;
          margin-bottom: 10px;
          display: flex;
          justify-content: flex-start;
          div {
            margin-right: 13px;
            //  width: 12px;

            img {
              width: 12px;
            }
          }
        }
        .toperson {
          color: #080707;
          font-size: 12px;
        }
      }
      .right-box {
        display: flex;
        width: 56px;
        background: #1b5297;
        color: #fff;
        align-items: center;
        justify-content: center;
        height: 26px;
        border-radius: 3px;
        cursor: pointer;
      }
      ::v-deep .el-button {
        width: 48px;
        height: 32px;
        background: #2d6cfc;
      }
    }
  }
}
::v-deep .el-timeline-item__timestamp.is-top {
  position: relative !important;
  left: -250px !important;
  width: 200px;
  position: relative;
  right: 250px;
  text-align: right;
}
::v-deep .el-timeline-item__content {
  margin-top: -35px !important;
}
</style>
