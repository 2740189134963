<!-- @功能组件 -->
<template>
  <div
    class="atsomeperson"
    v-show="atListIsShow && atUserList.length > 0 && atUserListFlag"
  >
    <div class="attitle">
      <!-- 选择要@的人或者是直接输入@用户名 -->
      {{ $t("chatter.sel.u") }}
    </div>
    <div class="atname">
      <div
        v-for="(item, index) in atUserList"
        :key="index"
        @click="chooseName(item.name)"
        class="at-box"
      >
        <img
          :src="`${imgUrlCookie}/chatterzone.action?m=showPhoto&userid=${item.id}&binding=${tokenCookie}`"
          alt=""
        />
        <div>{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
import { queryAtUsers } from "../api";

export default {
  props: {
    //是否显示@列表
    atListIsShow: {
      type: Boolean,
      default: false,
    },
    //输入框的值
    problemValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tokenCookie: "", //token
      imgUrlCookie: "", //域名
      atIndex: -1, //@出现的下标
      atUserList: [], //@列表数据
      atUserListFlag: false, //控制@列表数据显示和隐藏
    };
  },
  watch: {
    problemValue(newval) {
      if (newval !== "") {
        this.changInputValue(newval);
      }
    },
  },
  mounted() {
    //token
    this.tokenCookie = this.$CCDK.CCToken.getToken();
    //域名
    this.imgUrlCookie = VueCookies.get("domainName");
  },
  methods: {
    //选中的要@ 的某人
    chooseName(name) {
      this.$emit("chooseNameValue", name);
      this.atUserListFlag = false;
    },
    // 监听输入框的值的变化
    changInputValue(value) {
      this.atIndex = value.lastIndexOf("@");
      value = value.split("@"); // 以@作为分隔符转为数组，永远取最后一个@后的输入内容
      var searchValue = value[value.length - 1];
      if (this.atIndex > -1 && searchValue) {
        this.queryAtUsers(searchValue);
      }
    },

    // 查询@用户列表
    async queryAtUsers(value) {
      var rightIndex = value.indexOf("[");
      var leftIndex = value.indexOf("]");
      //判断字符串中包含[或]，等于-1不包含[],才可以进行搜索
      if (rightIndex === -1 && leftIndex === -1) {
        var param = {
          searchKey: value,
        };
        let res = await queryAtUsers(param);
        if (res.result) {
          this.atUserList = res.data.users;
          this.atUserListFlag = true;
        }
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.atsomeperson {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  // color: #0b6ea4;
  line-height: 20px;
  .attitle {
    color: #666666;
    margin-bottom: 4px;
  }
  .atname {
    display: flex;
    flex-direction: column;
    height: 100px;
    overflow-y: auto;
    // border: 1px solid #dedcda;
  }

  .at-box {
    display: flex;
    vertical-align: middle;
    align-items: center;
    margin-bottom: 4px;
    font-size: 13px;
    img {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
  }
  .at-box:hover {
    color: #006dcc;
    cursor: pointer;
  }
}
</style>