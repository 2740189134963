<!-- 删除帖子或者是评论弹框 -->
<template>
  <!-- 提示 -->
  <el-dialog
    :title="$t('label_popup_infoz')"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    append-to-body
    width="330px"
    height="200px"
  >
    <div>
      <!-- 确定要删除这条{{ editType == "post" ? "帖子" : "评论" }}吗？ -->
      {{
        editType == "post"
          ? $t("label.weixin.delete.ccchat")
          : $t("vue_label_ccchat_notice_deletecommentcertification")
      }}
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelDel" size="mini">
        <!-- 取消 -->
        {{ $t("button_partner_cancel") }}</el-button
      >
      <el-button type="primary" @click="saveDel" size="mini">
        <!-- 确定 -->
        {{ $t("label.ems.confirm") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  GetCcchatMicroPostRemoveMicroPost,
  removeMicroComment,
} from "../api.js";
export default {
  props: {
    //是否显示删除弹框
    dialogVisibleDel: {
      type: Boolean,
      default: false,
    },
    editType: {
      type: String,
      default: "",
    },
    //编辑内容
    postEdit: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  watch: {
    dialogVisibleDel(newval) {
      if (newval !== "") {
        this.dialogVisible=newval;
      }
    },
  },
  methods: {
    //取消删除
    cancelDel() {
      this.$emit("cancelDel");
    },
    handleClose() {
      this.$emit("cancelDel");
    },
    //确定删除
    async saveDel() {
      var res;
      //删除帖子
      if (this.editType === "post") {
        var params = {
          feedId: this.postEdit.id,
        };
        res = await GetCcchatMicroPostRemoveMicroPost(params);
        if (res.result) {
          // this.$message.success("删除帖子成功");
          this.$message.success(
            this.$i18n.t("vue_label_ccchat_notice_deletepostsuccess")
          );
        } else {
          // this.$message.info("删除帖子失败");
          this.$message.info(
            this.$i18n.t("vue_label_ccchat_notice_deletepostfail")
          );
        }
      } else if (this.editType === "comment") {
        let params = {
          commentId: this.postEdit.id,
        };
        // 删除评论
        res = await removeMicroComment(params);
        if (res.result) {
          this.$message.success(
            this.$i18n.t("vue_label_ccchat_notice_deletecommentsuccess")
          );
        } else {
          this.$message.info(
            this.$i18n.t("vue_label_ccchat_notice_deletecommentfail")
          );
        }
      }

      //删除成功调取查询帖子接口,重新渲染数据
      if (res.result) {
        this.$emit("saveDel");
      }
    },
  },
};
</script>

<style lang='scss' scoped>
</style>